import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { patchTrainingArticleBlock } from "../../../../services/training/content";
import { useTranslation } from "react-i18next";

const InfoMarkdown = ({ training, content, isEditing, setBlocks, blocks }) => {
  const { t } = useTranslation();

  // Local state for editable text and title
  const [markdownContent, setMarkdownContent] = useState(content?.text || "");
  const [articleName, setArticleName] = useState(content?.name || "");
  // Saving/loading state
  const [isSaving, setIsSaving] = useState(false);
  // Error state for possible errors
  const [error, setError] = useState(null);

  // Update local state when `content` changes
  useEffect(() => {
    setMarkdownContent(content?.text || "");
    setArticleName(content?.name || "");
  }, [content]);

  // Save handler
  const handleSave = async () => {
    setIsSaving(true);
    setError(null);

    try {
      // Call your PATCH method passing the article uuid
      const updatedArticle = await patchTrainingArticleBlock(content.uuid, {
        text: markdownContent,
        name: articleName,
      });

      // Optionally update local state with data returned from the server
      setMarkdownContent(updatedArticle?.text || "");
      setArticleName(updatedArticle?.name || "");

      // Update blocks state after content changes
      setBlocks((prevBlocks) =>
        prevBlocks.map((block) => ({
          ...block,
          contents: block.contents.map((c) =>
            c.content.uuid === content.uuid ? { ...c, content: updatedArticle } : c
          ),
        }))
      );
    } catch (err) {
      console.error("Ошибка при сохранении статьи:", err);
      setError(t("training.info_markdown.save_error", "Не удалось сохранить изменения. Попробуйте ещё раз."));
    } finally {
      setIsSaving(false);
    }
  };

  // Non-edit mode: just show the Markdown
  if (!isEditing) {
    return (
      <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-6 p-6 h-[80vh] overflow-y-scroll">
        <h2 className="text-xl font-bold mb-4">
          {content?.name || t("training.info_markdown.no_title", "Без названия")}
        </h2>
        <ReactMarkdown remarkPlugins={[remarkGfm]} className="prose max-w-none">
          {content?.text || ""}
        </ReactMarkdown>
      </div>
    );
  }

  // Edit mode: input, textarea, preview, and "Save" button
  return (
    <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-6 p-6 h-[80vh] overflow-y-scroll">
      <input
        type="text"
        className="w-full p-2 border rounded-md mb-4"
        value={articleName}
        onChange={(e) => setArticleName(e.target.value)}
        placeholder={t("training.info_markdown.placeholder_title", "Введите название статьи")}
        disabled={isSaving}
      />
      <textarea
        className="w-full h-40 p-2 border rounded-md mb-4"
        value={markdownContent}
        onChange={(e) => setMarkdownContent(e.target.value)}
        disabled={isSaving}
      />

      {/* Markdown Preview */}
      <ReactMarkdown remarkPlugins={[remarkGfm]} className="prose max-w-none">
        {markdownContent}
      </ReactMarkdown>

      {/* Save button and possible error message */}
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}

      <button
        className="bg-blue-500 text-white px-4 py-2 rounded-2xl disabled:opacity-50 self-start"
        onClick={handleSave}
        disabled={isSaving}
      >
        {isSaving ? t("training.info_markdown.saving", "Сохранение...") : t("training.info_markdown.save", "Сохранить")}
      </button>
    </div>
  );
};

export default InfoMarkdown;
