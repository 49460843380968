import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOrgProgress } from "../../../services/training/progressService";
import { useTranslation } from 'react-i18next';

const OrganizationProgressTable = ({ forWhom }) => {
  const { t } = useTranslation();
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllOrgData = async () => {
      try {
        setLoading(true);
        
        // Make three separate API calls with different forWhom values
        const [result1, result2, result3] = await Promise.all([
          getOrgProgress({ forWhom: 0 }),
          getOrgProgress({ forWhom: 1 }),
          getOrgProgress({ forWhom: 2 })
        ]);
        
        setData1(result1);
        setData2(result2);
        setData3(result3);
        setLoading(false);
      } catch (err) {
        setError(t("training.organization_progress.fetch_error"));
        setLoading(false);
        console.error('Error fetching data:', err);
      }
    };

    fetchAllOrgData();
    // We're not including forWhom in the dependency array 
    // as we want to load all data sets only once
  }, [t]);

  // Get the current data based on forWhom prop
  const getCurrentData = () => {
    switch (forWhom) {
      case 0:
        return data1;
      case 1:
        return data2;
      case 2:
        return data3;
      default:
        return data1;
    }
  };

  const orgData = getCurrentData();

  // Calculate totals for all metrics
  const calculateTotals = (data) => {
    return data.reduce((acc, org) => {
      return {
        users_started_count: acc.users_started_count + org.users_started_count,
        courses_started_count: acc.courses_started_count + org.courses_started_count,
        courses_completed_count: acc.courses_completed_count + org.courses_completed_count,
        students_count: acc.students_count + org.students_count
      };
    }, {
      users_started_count: 0,
      courses_started_count: 0,
      courses_completed_count: 0,
      students_count: 0
    });
  };

  const totals = calculateTotals(orgData);

  const formatDateTime = () => {
    const now = new Date();
    return now.getFullYear() + '-' +
      String(now.getMonth() + 1).padStart(2, '0') + '-' +
      String(now.getDate()).padStart(2, '0') + ' ' +
      String(now.getHours()).padStart(2, '0') + ':' +
      String(now.getMinutes()).padStart(2, '0') + ':' +
      String(now.getSeconds()).padStart(2, '0');
  };

  const handleOrgClick = (organization) => {
    if (forWhom === 2) {
      navigate(`/training_course_test_teacher_stats/${organization.uuid}`, { 
        state: { 
          organizationName: organization.name 
        }
      });
    } else {
      navigate(`/training_course_test_stats/${organization.uuid}`, { 
        state: { 
          organizationName: organization.name 
        }
      });
    }
  };

  const handleSort = (field) => {
    const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(newDirection);

    const sortData = (dataArray) => {
      return [...dataArray].sort((a, b) => {
        if (typeof a[field] === 'string') {
          return newDirection === 'asc' 
            ? a[field].localeCompare(b[field]) 
            : b[field].localeCompare(a[field]);
        } else {
          return newDirection === 'asc' 
            ? a[field] - b[field] 
            : b[field] - a[field];
        }
      });
    };

    // Sort all three datasets to maintain consistent sorting across tabs
    setData1(sortData(data1));
    setData2(sortData(data2));
    setData3(sortData(data3));
  };

  const renderSortIcon = (field) => {
    if (sortField !== field) return null;
    return (
      <span className="ml-1">
        {sortDirection === 'asc' ? '▲' : '▼'}
      </span>
    );
  };

  if (loading) {
    return (
      <div className="p-6 flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 flex justify-center items-center h-64">
        <div className="text-red-500 text-center">
          <p className="text-xl font-semibold">{error}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            {t("training.organization_progress.try_again")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full overflow-hidden mt-0">
      <h2 className="text-2xl font-bold text-gray-800 mb-2">
        {t("training.organization_progress.title")}
      </h2>
      <div className="flex flex-col overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="sticky top-0 z-10">
              <tr className="w-full h-16 border-b border-gray-200 bg-gray-100">
                <th 
                  className="text-left pl-4 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('name')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.organization_progress.org_name")} {renderSortIcon('name')}
                  </div>
                </th>
                <th 
                  className="text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('users_started_count')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.organization_progress.users_started")} {renderSortIcon('users_started_count')}
                  </div>
                </th>
                <th 
                  className="text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('courses_started_count')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.organization_progress.courses_started")} {renderSortIcon('courses_started_count')}
                  </div>
                </th>
                <th 
                  className="text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('courses_completed_count')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.organization_progress.courses_completed")} {renderSortIcon('courses_completed_count')}
                  </div>
                </th>
                <th 
                  className="text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('students_count')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.organization_progress.students_count")} {renderSortIcon('students_count')}
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        
        {/* Total row fixed at the top */}
        <div className="w-full">
          <table className="min-w-full bg-white">
            <tbody>
              <tr className="h-16 bg-gray-100 border-t-2 border-b-2 border-gray-300 font-bold">
                <td className="pl-4 text-sm font-bold text-gray-800 w-1/5">
                  {t("training.organization_progress.total") || "TOTAL"}
                </td>
                <td className="text-sm text-gray-800 w-1/5">
                  <div className="flex items-center">
                    <span className="mr-2 font-bold">{totals.users_started_count}</span>
                    {totals.students_count > 0 && (
                      <div className="flex items-center">
                        <div className="w-24 h-2 bg-gray-200 rounded-full">
                          <div 
                            className="h-full bg-blue-600 rounded-full" 
                            style={{ width: `${Math.min((totals.users_started_count / totals.students_count) * 100, 100)}%` }}
                          ></div>
                        </div>
                        <span className="ml-2 text-xs text-gray-700 font-medium">
                          {((totals.users_started_count / totals.students_count) * 100).toFixed(1)}%
                        </span>
                      </div>
                    )}
                  </div>
                </td>
                <td className="text-sm text-gray-800 pl-4 w-1/5">
                  <span className="font-bold">{totals.courses_started_count}</span>
                </td>
                <td className="text-sm text-gray-800 pl-4 w-1/5">
                  <span className="font-bold">{totals.courses_completed_count}</span>
                </td>
                <td className="text-sm text-gray-800 pl-4 w-1/5">
                  <span className="font-bold">{totals.students_count}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        {/* Scrollable table body */}
        <div className="overflow-y-auto flex-1">
          <table className="min-w-full bg-white">
            <tbody>
              {orgData.length > 0 ? (
                orgData.map((org, index) => (
                  <tr 
                    key={org.uuid} 
                    className={`h-16 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} border-b cursor-pointer hover:bg-blue-50 transition-colors`}
                    onClick={() => handleOrgClick(org)}
                  >
                    <td className="pl-4 text-sm font-medium text-gray-800 w-1/5">
                      {org.name}
                    </td>
                    <td className="text-sm text-gray-600 w-1/5">
                      <div className="flex items-center">
                        <span className="mr-2 font-medium">{org.users_started_count}</span>
                        {org.students_count > 0 && (
                          <div className="flex items-center">
                            <div className="w-24 h-2 bg-gray-200 rounded-full">
                              <div 
                                className="h-full bg-blue-500 rounded-full" 
                                style={{ width: `${Math.min((org.users_started_count / org.students_count) * 100, 100)}%` }}
                              ></div>
                            </div>
                            <span className="ml-2 text-xs text-gray-500">
                              {((org.users_started_count / org.students_count) * 100).toFixed(1)}%
                            </span>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="text-sm text-gray-600 pl-4 w-1/5">
                      <span className="font-medium">{org.courses_started_count}</span>
                    </td>
                    <td className="text-sm text-gray-600 pl-4 w-1/5">
                      <span className="font-medium">{org.courses_completed_count}</span>
                    </td>
                    <td className="text-sm text-gray-600 pl-4 w-1/5">
                      <span className="font-medium">{org.students_count}</span>
                    </td>
                  </tr>
                ))
              ) : (
              <tr>
                <td colSpan="7" className="text-center py-8 text-gray-500">
                  {t("training.organization_progress.no_data")}
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
      </div>
      
      <div className="mt-4 flex justify-between items-center">
        <div className="text-sm text-gray-600">
          {t("training.organization_progress.showing", { count: orgData.length })}
        </div>
        
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-600">
            {t("training.organization_progress.last_updated", { date: formatDateTime() })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrganizationProgressTable;