import React, { useState } from "react";
import Sidebar from './Training/Sidebar';

import SearchBar from './Training/Catalog/SearchBar';
import CardGrid from './Training/Catalog/CardGrid';

// Rest of the Profile component remains the same...
export default function TrainingCatalog() {
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleMenuClick = (menuId) => {
    console.log("Menu clicked:", menuId);
    setSelectedMenu(menuId);
    // You can navigate or update state based on menu selection here
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6">
      <div className="max-w-7xl mx-auto flex gap-4">
        <Sidebar onMenuClick={handleMenuClick} />
        <div className="flex-1 space-y-6">
          {/* <SearchBar /> */}
          <CardGrid />
        </div>
      </div>
    </div>
  );
}