import React, { useState } from "react";
import Sidebar from './Training/Sidebar';

export default function CreateTraining() {

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6">
      <div className="max-w-7xl mx-auto flex gap-4">
        <Sidebar />
      </div>
    </div>
  );
}