import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import Sidebar from './Training/Sidebar';
import VideoNavigation from './Training/Course/VideoNavigation';
import VideoPlayer from './Training/Course/VideoPlayer';
import StartInfo from './Training/Course/StartInfo';
import Info from './Training/Course/Info';
import UpdateInfo from './Training/Course/Crud/UpdateInfo';
import Progress from './Training/Course/Progress';
import Blocks from './Training/Course/Blocks';
import Content from './Training/Course/Content';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import UpdateBlocks from './Training/Course/Crud/UpdateBlocks';
import MCQTestWrapper from './Training/Course/Crud/TestUpdate';
import { getTrainingByUuid } from '../../services/training/catalogService';
import { getTrainingsBlock } from '../../services/training/content';

export default function Training() {
  const { t } = useTranslation();
  const { uuid } = useParams();
  
  // Define translation-based tab labels
  const courseTab = t("training.course_tab.course", "Про курс");
  const contentTab = t("training.course_tab.content", "Контент");
  const [activeTab, setActiveTab] = useState(courseTab);
  
  const [training, setTraining] = useState(null);
  const [blocks, setBlocks] = useState([]);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isStart, setIsStart] = useState(false);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!uuid) return;

    setLoading(true);
    // Parallel API calls for training and blocks
    Promise.all([getTrainingByUuid(uuid), getTrainingsBlock({ training: uuid })])
      .then(([trainingData, blocksData]) => {
        setTraining(trainingData);
        setIsStart(trainingData.is_start);
        setBlocks(blocksData);

        // Select the first content if available
        if (blocksData.length > 0 && blocksData[0].contents.length > 0) {
          setContent(blocksData[0].contents[0].content);
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message || t("training.course.failed_load", "Failed to load training data"));
        setLoading(false);
      });
  }, [uuid, t]);

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6">
      <div className="max-w-7xl mx-auto flex gap-4">
        <Sidebar />

        <div className="flex-1 space-y-5 relative">
          {loading ? (
            <SimpleLoader className="w-12 h-12" />
          ) : error ? (
            <div className="flex justify-center items-center min-h-screen">
              <p className="text-lg text-red-500">
                {t("training.course.error_label", "Error:")} {error}
              </p>
            </div>
          ) : (
            <>
              {/* Wrapper for video/content */}
              <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-0">
                <VideoNavigation
                  setBlocks={setBlocks}
                  blocks={blocks}
                  content={content}
                  setContent={setContent}
                  isStart={isStart}
                />
                {content?.content_type === "video" ? (
                  <VideoPlayer
                    training={training}
                    blocks={blocks}
                    setBlocks={setBlocks}
                    content={content}
                    isEditing={isEditing}
                  />
                ) : content?.content_type === "article" ? (
                  <Content
                    training={training}
                    content={content}
                    isEditing={isEditing}
                    setBlocks={setBlocks}
                    blocks={blocks}
                  />
                ) : content?.content_type === "mcqtest" ? (
                  <MCQTestWrapper
                    isEditing={isEditing}
                    blocks={blocks}
                    setBlocks={setBlocks}
                    content={content}
                    setContent={setContent}
                  />
                ) : null}
              </div>

              {/* Editing mode with tabs */}
              {isEditing ? (
                <>
                  {/* Tab navigation for editing */}
                  <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl p-4 mb-4">
                    <div className="flex bg-gray-100 p-1 rounded-xl">
                      {[courseTab, contentTab].map((tab) => (
                        <button
                          key={tab}
                          onClick={() => setActiveTab(tab)}
                          className={`flex-grow text-center px-6 py-2 rounded-lg text-sm font-medium transition-colors ${
                            activeTab === tab
                              ? "bg-white text-black shadow"
                              : "bg-transparent text-gray-500"
                          }`}
                        >
                          {tab}
                        </button>
                      ))}
                    </div>
                  </div>

                  {/* Content of active tab */}
                  {activeTab === courseTab ? (
                    <UpdateInfo
                      training={training}
                      setTraining={setTraining}
                      setIsEditing={setIsEditing}
                    />
                  ) : (
                    <UpdateBlocks
                      blocks={blocks}
                      setBlocks={setBlocks}
                      content={content}
                      setContent={setContent}
                    />
                  )}
                </>
              ) : (
                <>
                  <Info training={training} />
                  {!isStart ? (
                    <StartInfo
                      training={training}
                      isStart={isStart}
                      setIsStart={setIsStart}
                    />
                  ) : (
                    <Progress
                      training={training}
                      blocks={blocks}
                      setBlocks={setBlocks}
                    />
                  )}
                  <Blocks
                    blocks={blocks}
                    content={content}
                    setContent={setContent}
                  />
                </>
              )}
            </>
          )}
        </div>

        {/* Button to toggle editing mode */}
        <div className="sticky top-6 self-start">
          {!isEditing && training?.is_author && (
            <button
              className="bg-blue-500 text-white rounded-2xl shadow-lg p-3 flex items-center space-x-2"
              onClick={() => setIsEditing(true)}
            >
              <PencilIcon className="w-6 h-6" />
              <span>{t("training.course.edit", "Изменить")}</span>
            </button>
          )}
          {isEditing && (
            <button
              className="bg-gray-500 text-white rounded-2xl shadow-lg p-3 flex items-center space-x-2"
              onClick={() => setIsEditing(false)}
            >
              <span>{t("training.course.cancel", "Отмена")}</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
