import React from "react";
import { postTrainingEnrollByUuid } from "../../../../services/training/catalogService";
import { useTranslation } from "react-i18next";

const StartInfo = ({ training, isStart, setIsStart }) => {
  const { t } = useTranslation();

  const handleStart = async () => {
    try {
      await postTrainingEnrollByUuid(training.uuid);
      setIsStart(true);
    } catch (error) {
      console.error(t("training.start_info.enroll_error", "Ошибка при начале курса:"), error);
    }
  };

  return (
    <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-6 flex flex-col gap-6 border-1 border-transparent bg-gradient-to-r from-blue-500 to-purple-500 p-[1px]">
      <div className="bg-white rounded-2xl p-6">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-bold text-gray-900">
            {t(
              "training.start_info.header",
              "Начните курс и получите сертификат от Международного общественного фонда «Білім-Инновация»!"
            )}
          </h2>
          <button
            onClick={handleStart}
            className="px-12 py-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white text-base font-semibold rounded-lg shadow-lg transform transition-all hover:scale-105 hover:shadow-2xl hover:from-blue-600 hover:to-purple-600 whitespace-nowrap"
          >
            {t("training.start_info.start_button", "🚀 Начать курс")}
          </button>
        </div>

        {/* Locked progress preview */}
        <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl p-6 opacity-50 pointer-events-none select-none">
          <h2 className="text-lg font-semibold mb-4">
            {t("training.start_info.progress_title", "Прогресс")}
          </h2>
          <div className="flex flex-wrap gap-4">
            <div className="flex items-center gap-2">
              <span className="bg-purple-500 text-white text-sm px-3 py-1 rounded-full">0/10</span>
              <span>{t("training.start_info.video_watched", "Видео просмотрено")}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="bg-pink-500 text-white text-sm px-3 py-1 rounded-full">0/10</span>
              <span>{t("training.start_info.articles_read", "Артикулей прочитано")}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="bg-blue-500 text-white text-sm px-3 py-1 rounded-full">0/10</span>
              <span>{t("training.start_info.tests_completed", "Тестов сдано")}</span>
            </div>
          </div>
          <div className="mt-4 w-full bg-gray-200 rounded-full h-2">
            <div className="bg-purple-500 h-2 rounded-full" style={{ width: "0%" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartInfo;
