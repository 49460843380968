import React, { useState, useEffect, useContext, useRef } from "react";
import { patchTrainingVideoBlock } from "../../../../services/training/content";
import { postContentProgress, patchContentProgress } from "../../../../services/training/progressService";
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../utils/contexts/UserContext';

const VideoPlayer = ({ training, content, isEditing, blocks, setBlocks }) => {
  const [localName, setLocalName] = useState(content.name || "");
  const [localAuthors, setLocalAuthors] = useState(content.authors || "");
  const [localContacts, setLocalContacts] = useState(content.contacts || "");
  const [localUrl, setLocalUrl] = useState(content.url || "");
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(content.progress_percentage || 0);
  const [showCompletionNotice, setShowCompletionNotice] = useState(false);
  const playerRef = useRef(null);
  const timerRef = useRef(null);
  const lastSavedPercentageRef = useRef(content.progress_percentage || 0);
  const lastPositionRef = useRef(0);
  const initialSeekDoneRef = useRef(false); // Флаг для отслеживания начального позиционирования

  useEffect(() => {
    setLocalName(content.name || "");
    setLocalAuthors(content.authors || "");
    setLocalContacts(content.contacts || "");
    setLocalUrl(content.url || "");
    setCompletionPercentage(content.progress_percentage || 0);
    lastSavedPercentageRef.current = content.progress_percentage || 0;
    lastPositionRef.current = 0; // Сбрасываем позицию при изменении контента
    initialSeekDoneRef.current = false; // Сбрасываем флаг начального позиционирования
  }, [content]);

  // YouTube Player API integration
  useEffect(() => {
    // Only setup YouTube API when the iframe is active
    if (!isPlaying) return;

    // Load YouTube API script if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = initializePlayer;
    } else {
      initializePlayer();
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isPlaying, content.video_id]);

  const initializePlayer = () => {
    // Wait for the iframe to be available in the DOM
    const checkForIframe = setInterval(() => {
      const iframe = document.querySelector(`iframe[src*="${content.video_id}"]`);
      if (iframe) {
        clearInterval(checkForIframe);
        
        try {
          // Create a new YouTube player instance
          playerRef.current = new window.YT.Player(iframe, {
            events: {
              'onReady': onPlayerReady,
              'onStateChange': onPlayerStateChange
            }
          });
        } catch (error) {
          console.error("Ошибка при инициализации YouTube плеера:", error);
        }
      }
    }, 300);

    // Защита от зависания - очищаем интервал через 10 секунд, если iframe не найден
    setTimeout(() => {
      clearInterval(checkForIframe);
    }, 10000);
  };

  const onPlayerReady = (event) => {
    // Получаем сохраненный прогресс пользователя
    const savedPercentage = content.progress_percentage || 0;
    
    // Только если есть сохраненный прогресс и еще не делали начальное позиционирование
    if (savedPercentage > 0 && !initialSeekDoneRef.current) {
      try {
        // Получаем длительность видео
        const duration = playerRef.current.getDuration();
        
        // Рассчитываем позицию для начала просмотра в секундах
        const startPosition = (savedPercentage / 100) * duration;
        
        // Если видео просмотрено более чем на 95%, начинаем с начала
        // (опционально, можно удалить эту проверку, если нужно всегда начинать с последней позиции)
        if (savedPercentage < 95) {
          // Переходим к сохраненной позиции
          playerRef.current.seekTo(startPosition, true);
          lastPositionRef.current = startPosition;
        }
        
        // Устанавливаем флаг, что начальное позиционирование выполнено
        initialSeekDoneRef.current = true;
      } catch (error) {
        console.error("Ошибка при установке начальной позиции:", error);
      }
    }
    
    // Start tracking video progress
    startProgressTracking();
  };

  const onPlayerStateChange = (event) => {
    // Защита от ошибок, если плеер еще не инициализирован полностью
    if (!playerRef.current || typeof playerRef.current.getCurrentTime !== 'function' || 
        typeof playerRef.current.getDuration !== 'function') {
      return;
    }
    
    // Получаем текущее время видео
    const currentTime = playerRef.current.getCurrentTime() || 0;
    const duration = playerRef.current.getDuration() || 0;
    
    if (duration <= 0) {
      return; // Выходим, если длительность невалидна
    }
    
    const currentPercentage = Math.round((currentTime / duration) * 100);
    
    // YT.PlayerState.PLAYING = 1
    if (event.data === 1) {
      // Проверяем, была ли перемотка
      if (Math.abs(currentTime - lastPositionRef.current) > 3) {
        // Значительное изменение времени - была перемотка
        // Обновляем локальное состояние
        setCompletionPercentage(currentPercentage);
        
        // Проверяем, нужно ли отправить обновление на сервер
        if (currentPercentage > lastSavedPercentageRef.current) {
          if (Math.floor(currentPercentage / 5) > Math.floor(lastSavedPercentageRef.current / 5) || currentPercentage === 100) {
            updateProgress(currentPercentage);
          }
        }
      }
      
      // Начинаем трекинг
      startProgressTracking();
    } 
    // YT.PlayerState.PAUSED = 2
    else if (event.data === 2) {
      // Пауза - проверяем, была ли перемотка перед паузой
      if (Math.abs(currentTime - lastPositionRef.current) > 3) {
        // Была перемотка, обновляем состояние
        setCompletionPercentage(currentPercentage);
        
        // Проверяем, нужно ли отправить обновление на сервер
        if (currentPercentage > lastSavedPercentageRef.current) {
          if (Math.floor(currentPercentage / 5) > Math.floor(lastSavedPercentageRef.current / 5) || currentPercentage === 100) {
            updateProgress(currentPercentage);
          }
        }
      }
      
      // Останавливаем интервал трекинга
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
    // Для других состояний просто останавливаем интервал
    else {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
    
    // Обновляем последнюю известную позицию
    lastPositionRef.current = currentTime;
  };

  const updateProgress = async (newPercentage) => {
    // Проверка валидности newPercentage
    if (typeof newPercentage !== 'number' || isNaN(newPercentage) || newPercentage < 0 || newPercentage > 100) {
      console.error("Невалидное значение процента:", newPercentage);
      return;
    }
    
    // Если новый процент меньше или равен сохраненному ранее, не обновляем
    if (newPercentage <= lastSavedPercentageRef.current) {
      return;
    }

    // Округляем процент до ближайшего кратного 5
    const roundedPercentage = Math.floor(newPercentage / 5) * 5;
    
    // Если округленный процент не изменился с последнего сохранения, не обновляем
    if (roundedPercentage <= lastSavedPercentageRef.current && roundedPercentage !== 100) {
      return;
    }

    try {
      let updatedProgress;
      
      if (content.progress_uuid) {
        // Обновляем существующий прогресс
        updatedProgress = await patchContentProgress(content.progress_uuid, {
          percentage: newPercentage
        });
      } else {
        // Создаем новый прогресс
        updatedProgress = await postContentProgress({
          content: content.content,
          percentage: newPercentage,
          user: user.uuid,
        });
      }
      
      // Проверяем, что updatedProgress валиден, прежде чем обновлять ссылки
      if (updatedProgress) {
        // Обновляем последний сохраненный процент
        lastSavedPercentageRef.current = newPercentage;
        
        // Обновляем локальное состояние с обновленным прогрессом
        updateLocalProgressState(newPercentage, updatedProgress.uuid);
      }
    } catch (error) {
      console.error("Error updating progress:", error);
    }
  };

  const updateLocalProgressState = (newPercentage, progressUuid = null) => {
    // Update blocks state
    setBlocks((prevBlocks) =>
      prevBlocks.map((block) => {
        return {
          ...block,
          contents: block.contents.map((c) => {
            if (c.content.uuid === content.uuid) {
              const updatedContent = { 
                ...c.content,
                progress_percentage: newPercentage
              };
              
              // Only update progress_uuid if provided (when creating new progress)
              if (progressUuid && !c.content.progress_uuid) {
                updatedContent.progress_uuid = progressUuid;
              }
              
              return { ...c, content: updatedContent };
            }
            return c;
          }),
        };
      })
    );
  };

  const startProgressTracking = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      if (!playerRef.current || typeof playerRef.current.getCurrentTime !== 'function' || 
          typeof playerRef.current.getDuration !== 'function') {
        return;
      }
      
      try {
        const currentTime = playerRef.current.getCurrentTime();
        const duration = playerRef.current.getDuration();
        
        if (!duration || duration <= 0) {
          return; // Пропускаем, если длительность невалидна
        }
        
        const percentage = Math.round((currentTime / duration) * 100);
        
        // Обновляем состояние для отображения текущего процента
        setCompletionPercentage(percentage);
        
        // Обновляем последнюю известную позицию
        lastPositionRef.current = currentTime;
        
        // Если процент больше, чем существующий в БД, обновляем
        if (percentage > lastSavedPercentageRef.current) {
          // Проверяем, достигли ли мы нового 5%-ого порога
          if (Math.floor(percentage / 5) > Math.floor(lastSavedPercentageRef.current / 5) || percentage === 100) {
            updateProgress(percentage);
          }
        }
        
        if (percentage > 90 && !showCompletionNotice) {
          setShowCompletionNotice(true);
        }
      } catch (error) {
        console.error("Ошибка при отслеживании прогресса:", error);
        // Останавливаем интервал при ошибке, чтобы не продолжать генерировать ошибки
        clearInterval(timerRef.current);
      }
    }, 1000);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const updated = await patchTrainingVideoBlock(content.uuid, {
        name: localName,
        authors: localAuthors,
        contacts: localContacts,
        url: localUrl,
      });
      setBlocks((prevBlocks) =>
        prevBlocks.map((block) => {
          return {
            ...block,
            contents: block.contents.map((c) =>
              c.content.uuid === content.uuid ? { ...c, content: updated } : c
            ),
          };
        })
      );
    } catch (error) {
      console.error("Ошибка при сохранении видео:", error);
    } finally {
      setIsSaving(false);
    }
  };

  // Режим редактирования: добавляем блок превью ПЕРЕД формой
  if (isEditing) {
    return (
      <div className="relative w-full space-y-4">
        {/* --- Превью-блок (не меняем ничего внутри, только вставляем выше формы) --- */}
        <div
          className="relative w-full h-[400px] sm:h-[500px] cursor-pointer bg-gradient-to-r from-white to-purple-200"
          onClick={() => setIsPlaying(true)}
        >
          {/* Логотипы */}
          <div className="absolute top-4 left-4">
            <img
              src="https://optim.tildacdn.com/tild6434-3036-4961-b539-313663306438/-/resize/400x/-/format/webp/yt-02.png"
              alt="Bilim Innovation Logo"
              className="w-20 h-auto"
            />
          </div>

          {/* Текст в правом верхнем углу */}
          <div className="absolute top-4 right-4 text-black text-sm font-medium">
            {training.name}
          </div>

          {/* Контейнер с текстом в центре слева */}
          <div className="absolute top-1/2 left-12 transform -translate-y-1/2 text-black w-2/5 break-words flex flex-col gap-2">
            <div className="text-4xl font-extrabold">{localName}</div>
            {(localAuthors || localContacts) && (
              <div className="text-sm font-medium">
                {localAuthors && (
                  <p>
                    <strong>{t("training.authors")}:</strong> {localAuthors}
                  </p>
                )}
                {localContacts && (
                  <p>
                    <strong>{t("training.contacts")}:</strong> {localContacts}
                  </p>
                )}
              </div>
            )}
          </div>

          {/* Кнопка Play */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-20 h-20 bg-black bg-opacity-70 flex items-center justify-center rounded-full shadow-lg">
              <svg
                className="w-10 h-10 text-white"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            </div>
          </div>

          {/* Маленький текст внизу слева */}
          <div className="absolute bottom-4 left-4 text-sm text-gray-600">
            © {t("training.copyrights")}
          </div>
        </div>

        {/* --- Форма редактирования --- */}
        <div className="relative w-full bg-white p-4 rounded-2xl shadow space-y-4">
          <h2 className="text-lg font-bold">{t("training.edit_video")}</h2>

          <div>
            <label className="block text-sm font-medium mb-1">{t("training.name")}</label>
            <input
              className="border p-2 rounded-2xl w-full"
              value={localName}
              onChange={(e) => setLocalName(e.target.value)}
              disabled={isSaving}
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">{t("training.authors")}</label>
            <input
              className="border p-2 rounded-2xl w-full"
              value={localAuthors}
              onChange={(e) => setLocalAuthors(e.target.value)}
              disabled={isSaving}
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">{t("training.contacts")}</label>
            <input
              className="border p-2 rounded-2xl w-full"
              value={localContacts}
              onChange={(e) => setLocalContacts(e.target.value)}
              disabled={isSaving}
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">URL (YouTube)</label>
            <input
              className="border p-2 rounded-2xl w-full"
              value={localUrl}
              onChange={(e) => setLocalUrl(e.target.value)}
              disabled={isSaving}
            />
          </div>

          <button
            onClick={handleSave}
            className="bg-blue-500 text-white px-4 py-2 rounded-2xl mt-2 disabled:opacity-50"
            disabled={isSaving}
          >
            {isSaving ? `${t("training.saving")}...` : t("training.save")}
          </button>
        </div>
      </div>
    );
  }

  // Режим просмотра
  return (
    <div className="relative w-full">
      {isPlaying ? (
        <div className="relative">
          <iframe
            className="w-full h-[400px] sm:h-[500px]"
            src={`https://www.youtube.com/embed/${content.video_id}?enablejsapi=1&autoplay=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          {/* Completion notice */}
          {showCompletionNotice && (
            <div className="mt-2 bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded">
              <strong>{t("training.congratulations")}!</strong> {t("training.video_almost_completed")}
            </div>
          )}
        </div>
      ) : (
        <div
          className="relative w-full h-[400px] sm:h-[500px] cursor-pointer bg-gradient-to-r from-white to-purple-200"
          onClick={() => setIsPlaying(true)}
        >
          {/* Логотипы */}
          <div className="absolute top-4 left-4">
            <img
              src="https://optim.tildacdn.com/tild6434-3036-4961-b539-313663306438/-/resize/400x/-/format/webp/yt-02.png"
              alt="Bilim Innovation Logo"
              className="w-20 h-auto"
            />
          </div>

          {/* Текст в правом верхнем углу */}
          <div className="absolute top-4 right-4 text-black text-sm font-medium">
            {training.name}
          </div>

          {/* Контейнер с текстом в центре слева */}
          <div className="absolute top-1/2 left-12 transform -translate-y-1/2 text-black w-2/5 break-words flex flex-col gap-2">
            <div className="text-4xl font-extrabold">{content.name}</div>
            {(content.authors || content.contacts) && (
              <div className="text-sm font-medium">
                {content.authors && (
                  <p>
                    <strong>{t("training.authors")}:</strong> {content.authors}
                  </p>
                )}
                {content.contacts && (
                  <p>
                    <strong>{t("training.contacts")}:</strong> {content.contacts}
                  </p>
                )}
              </div>
            )}
          </div>

          {/* Кнопка Play */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-20 h-20 bg-black bg-opacity-70 flex items-center justify-center rounded-full shadow-lg">
              <svg
                className="w-10 h-10 text-white"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            </div>
          </div>

          {/* Маленький текст внизу слева */}
          <div className="absolute bottom-4 left-4 text-sm text-gray-600">
            © {t("training.copyrights")}
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;