import React, { useState, useEffect } from "react";
import { FireIcon, ClockIcon, TrophyIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { getWeeklyProgress } from "../../../../services/training/progressService";
import { useTranslation } from "react-i18next";

const FireMode = () => {
  const { t } = useTranslation();
  
  const [progressData, setProgressData] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    current_streak: 0
  });
  
  const [isLoading, setIsLoading] = useState(true);

  // Function to get the correct word form for "day" using translations
  const getDayWordForm = (count) => {
    if (count % 10 === 1 && count % 100 !== 11) {
      return t("training.firemode.day.singular", "день");
    } else if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) {
      return t("training.firemode.day.few", "дня");
    } else {
      return t("training.firemode.day.many", "дней");
    }
  };

  // Get current day of week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const today = new Date().getDay();
  // Convert to our format (0 = Monday, ..., 6 = Sunday)
  const currentDayIndex = today === 0 ? 6 : today - 1;

  // Days array using translation keys
  const days = [
    t("training.firemode.days.mon", "Пн"),
    t("training.firemode.days.tue", "Вт"),
    t("training.firemode.days.wed", "Ср"),
    t("training.firemode.days.thu", "Чт"),
    t("training.firemode.days.fri", "Пт"),
    t("training.firemode.days.sat", "Сб"),
    t("training.firemode.days.sun", "Вс")
  ];
  
  const dayKeys = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  
  const progress = dayKeys.map((day, index) => {
    if (progressData[day]) {
      return "completed"; // Day is completed
    } else if (index < currentDayIndex) {
      return "failed"; // Day is in the past and not completed
    } else if (index === currentDayIndex) {
      return "today"; // Today and not completed yet
    } else {
      return "future"; // Future day
    }
  });

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const data = await getWeeklyProgress();
        setProgressData(data);
      } catch (error) {
        console.error(t("training.firemode.fetch_error", "Failed to fetch progress data:"), error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProgress();
  }, [t]);

  return (
    <div className="bg-gradient-to-r from-purple-400 to-purple-500 p-4 rounded-xl shadow-lg flex flex-col space-y-3 relative">
      {/* Header */}
      <div className="text-white font-bold text-lg">
        {t("training.firemode.header", "🔥 Огненный режим")}
      </div>
      
      {/* Progress and Days Container */}
      <div className="flex space-x-2">
        {isLoading ? (
          <div className="text-white opacity-80 text-sm">
            {t("training.firemode.loading", "Загрузка данных...")}
          </div>
        ) : (
          progress.map((status, index) => (
            <div key={index} className="flex flex-col items-center">
              <div
                className={`w-8 h-8 mb-1 flex items-center justify-center rounded-full ${
                  status === "completed" ? "bg-orange-700 text-white" : 
                  status === "failed" ? "bg-gray-700 text-white" :
                  status === "today" ? "border-2 border-dashed border-white" : "border-2 border-white"
                }`}
              >
                {status === "completed" && <FireIcon className="w-5 h-5 text-yellow-300" />}
                {status === "failed" && <span className="text-lg">✕</span>}
              </div>
              <span className="text-sm text-white opacity-80">{days[index]}</span>
            </div>
          ))
        )}
      </div>
      
      {/* Description */}
      <div className="bg-purple-600 p-3 rounded-lg flex items-center space-x-3">
        <div className="flex-1">
          <h3 className="text-white font-semibold">
            {t("training.firemode.description.header", "Зажги огонек эрудированности")}
          </h3>
          <p className="text-white text-sm opacity-90">
            {t("training.firemode.description.text", "Хочешь стать умнее, чем вчера? Закончи один урок в день и ставь галочку на саморазвитии.")}
          </p>
          <div className="flex items-center mt-2 space-x-4 text-white opacity-80 text-sm">
            <div className="flex items-center">
              <ClockIcon className="w-4 h-4 mr-1" />
              {t("training.firemode.progress.time_remaining", "5 минут до цели")}
            </div>
            <div className="flex items-center">
              <TrophyIcon className="w-4 h-4 mr-1" />
              {t("training.firemode.progress.best_result", "Лучший результат:")} {progressData.current_streak} {getDayWordForm(progressData.current_streak)} {t("training.firemode.progress.streak_suffix", "подряд")}
            </div>
          </div>
        </div>
      </div>
      
      {/* Right-side Button */}
      <button className="absolute top-4 right-4 text-white">
        <ChevronRightIcon className="w-6 h-6" />
      </button>
    </div>
  );
};

export default FireMode;
