import React, { useState } from "react";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './Training/Sidebar';
import StudentProgressTable from './Stats/StudentsStats';
import { useTranslation } from "react-i18next";

export default function StudentsStats() {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const groupName = location.state?.groupName || t("training.students_stats.group_default", "Group");
  const [selectedMenu, setSelectedMenu] = useState(null);
  
  const handleMenuClick = (menuId) => {
    console.log("Menu clicked:", menuId);
    setSelectedMenu(menuId);
    // You can navigate or update state based on menu selection here
  };
  
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6">
      <div className="max-w-7xl mx-auto flex gap-4">
        <Sidebar onMenuClick={handleMenuClick} />
        <div className="flex-1 space-y-6">
          <div className="flex items-center mb-6">
            <button 
              onClick={handleBack}
              className="mr-4 px-3 py-2 bg-white rounded-2xl hover:bg-gray-50 transition-colors flex items-center shadow-sm"
            >
              <span className="mr-1">←</span> {t("training.students_stats.back", "Back")}
            </button>
            <h1 className="text-2xl font-bold text-gray-800">{groupName}</h1>
          </div>
          
          <StudentProgressTable 
            groupId={uuid} 
            groupName={groupName} 
          />
        </div>
      </div>
    </div>
  );
}
