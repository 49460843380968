import EditMCQTest from '../Crud/Test/EditMCQTest';
import TakeMCQTest from '../Crud/Test/TakeMCQTest';


export default function MCQTestWrapper({ isEditing, ...props }) {
  return isEditing ? (
    <EditMCQTest {...props} />
  ) : (
    <TakeMCQTest {...props} />
  );
}