import React, { useState } from "react";
import Sidebar from './Training/Sidebar';
import FireMode from './Training/Profile/FireMode';
import Leaderboard from './Training/Profile/Leaderboard';
import LeaderboardList from './Training/Profile/LeaderboardList';
import UserLeaderboard from './Training/Profile/UserLeaderboard';
import { useTranslation } from "react-i18next";

// Rest of the Profile component remains the same...
export default function TrainingProfile() {
  const { t } = useTranslation();

  const currentUser = {
    name: "Ты", // You may update this key if needed
    score: 470,
    place: 15, // Если не в топ-10
    avatar: "https://cdn-icons-png.flaticon.com/512/4149/4149655.png",
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6">
      <div className="max-w-7xl mx-auto flex gap-4">
        <Sidebar />
        <div className="flex-1 space-y-6">
          <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-0">
            <FireMode />
          </div>
          {/*
          <UserLeaderboard user={currentUser}/>
          <Leaderboard />
          <LeaderboardList />
          */}
          <div className="bg-white p-6 rounded-2xl shadow-md w-full">
            <div className="text-center py-8">
              <div className="mb-6">
                <svg className="w-16 h-16 mx-auto text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
                </svg>
              </div>
              <h2 className="text-xl font-bold mb-4">{t("training.profile.competition_soon")}</h2>
              <p className="text-gray-600 mb-6">
                {t("training.profile.competition_description")}
              </p>
              <div className="bg-purple-100 p-4 rounded-lg inline-block">
                <div className="flex items-center">
                  <svg className="w-5 h-5 text-purple-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                  </svg>
                  <span className="font-medium text-purple-700">
                    {t("training.profile.keep_going")}
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
