import React, { useState } from "react";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Sidebar from './Training/Sidebar';
import TeachersProgressTable from './Stats/TeachersStats';

export default function TeacherStats() {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const orgName = location.state?.orgName || t("training.teacher_stats.org_default", "Organization");
  const [selectedMenu, setSelectedMenu] = useState(null);
  
  const handleMenuClick = (menuId) => {
    console.log("Menu clicked:", menuId);
    setSelectedMenu(menuId);
    // You can navigate or update state based on menu selection here
  };
  
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6">
      <div className="max-w-7xl mx-auto flex gap-4">
        <Sidebar onMenuClick={handleMenuClick} />
        <div className="flex-1 space-y-6">
          <div className="flex items-center mb-6">
            <button 
              onClick={handleBack}
              className="mr-4 px-3 py-2 bg-white rounded-2xl hover:bg-gray-50 transition-colors flex items-center shadow-sm"
            >
              <span className="mr-1">←</span> {t("training.teacher_stats.back", "Back")}
            </button>
            <h1 className="text-2xl font-bold text-gray-800">{orgName}</h1>
          </div>
          
          <TeachersProgressTable 
            orgId={uuid} 
            orgName={orgName} 
          />
        </div>
      </div>
    </div>
  );
}
