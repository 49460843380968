import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getGroupProgress } from "../../../services/training/progressService";
import { useTranslation } from 'react-i18next';

const GroupProgressTable = ({ organizationId, organizationName }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [groupData, setGroupData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const handleGroupClick = (group) => {
    navigate(`/training_course_test_stats_group/${group.uuid}`, { 
      state: { 
        groupName: group.name 
      }
    });
  };

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        setLoading(true);
        console.log(organizationId);
        const result = await getGroupProgress({ organization: organizationId });
        setGroupData(result);
        setLoading(false);
      } catch (err) {
        setError(t("training.group_progress.fetch_error"));
        setLoading(false);
        console.error('Error fetching group data:', err);
      }
    };

    fetchGroupData();
  }, [organizationId, t]);

  const handleSort = (field) => {
    const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(newDirection);

    const sortedData = [...groupData].sort((a, b) => {
      if (typeof a[field] === 'string') {
        return newDirection === 'asc' 
          ? a[field].localeCompare(b[field]) 
          : b[field].localeCompare(a[field]);
      } else {
        return newDirection === 'asc' 
          ? a[field] - b[field] 
          : b[field] - a[field];
      }
    });

    setGroupData(sortedData);
  };

  const renderSortIcon = (field) => {
    if (sortField !== field) return null;
    return (
      <span className="ml-1">
        {sortDirection === 'asc' ? '▲' : '▼'}
      </span>
    );
  };

  if (loading) {
    return (
      <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-6">
        <div className="p-6 flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-6">
        <div className="p-6 flex justify-center items-center h-64">
          <div className="text-red-500 text-center">
            <p className="text-xl font-semibold">{error}</p>
            <button 
              onClick={() => window.location.reload()} 
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              {t("training.group_progress.try_again")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-6">
      <div className="p-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          {t("training.group_progress.title", { organizationName })}
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="w-full h-16 border-b border-gray-200 bg-gray-50">
                <th 
                  className="text-left pl-4 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('name')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.group_progress.group_name")} {renderSortIcon('name')}
                  </div>
                </th>
                <th 
                  className="text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('users_started_count')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.group_progress.users_started")} {renderSortIcon('users_started_count')}
                  </div>
                </th>
                <th 
                  className="text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('courses_started_count')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.group_progress.courses_started")} {renderSortIcon('courses_started_count')}
                  </div>
                </th>
                <th 
                  className="text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('courses_completed_count')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.group_progress.courses_completed")} {renderSortIcon('courses_completed_count')}
                  </div>
                </th>
                <th 
                  className="text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('students_count')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.group_progress.students_count")} {renderSortIcon('students_count')}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {groupData.length > 0 ? (
                groupData.map((group, index) => (
                  <tr 
                    key={group.uuid} 
                    className={`h-16 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} border-b hover:bg-gray-100 cursor-pointer`}
                    onClick={() => handleGroupClick(group)}
                  >
                    <td className="pl-4 text-sm font-medium text-gray-800">
                      {group.name}
                    </td>
                    <td className="text-sm text-gray-600">
                      <div className="flex items-center">
                        <span className="mr-2 font-medium">{group.users_started_count}</span>
                        {group.students_count > 0 && (
                          <div className="flex items-center">
                            <div className="w-24 h-2 bg-gray-200 rounded-full">
                              <div 
                                className="h-full bg-blue-500 rounded-full" 
                                style={{ width: `${Math.min((group.users_started_count / group.students_count) * 100, 100)}%` }}
                              ></div>
                            </div>
                            <span className="ml-2 text-xs text-gray-500">
                              {((group.users_started_count / group.students_count) * 100).toFixed(1)}%
                            </span>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="text-sm text-gray-600 pl-4">
                      <span className="font-medium">{group.courses_started_count}</span>
                    </td>
                    <td className="text-sm text-gray-600 pl-4">
                      <span className="font-medium">{group.courses_completed_count}</span>
                    </td>
                    <td className="text-sm text-gray-600 pl-4">
                      <span className="font-medium">{group.students_count}</span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center py-8 text-gray-500">
                    {t("training.group_progress.no_data")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        
        <div className="mt-6 flex justify-between items-center">
          <div className="text-sm text-gray-600">
            {t("training.group_progress.showing", { count: groupData.length })}
          </div>
          
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-600">
              {t("training.group_progress.last_updated", { date: new Date().toLocaleDateString() })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupProgressTable;
