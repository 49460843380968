import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  postTrainingsBlock,
  patchTrainingsBlock,
  postTrainingContentBlock,
  patchTrainingContentBlock,
  postTrainingArticleBlock,
  postTrainingVideoBlock,
  deleteTrainingContentBlock,
  deleteTrainingsBlock,
  postMCQTestBlock
} from "../../../../../services/training/content";
import { useTranslation } from "react-i18next";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

const UpdateBlocks = ({ blocks, setBlocks, content, setContent }) => {
  const { uuid } = useParams(); // Get training uuid from URL
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [order, setOrder] = useState("");
  const [editingBlockUuid, setEditingBlockUuid] = useState(null);

  // State for tracking which blocks are expanded or collapsed
  const [expandedBlocks, setExpandedBlocks] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const markdownText = t("training.update_blocks.markdown_text", `
# Markdown for Article
Markdown is a lightweight markup language that allows you to format text using a simple syntax.
## Features
- **Bold text**: **text**
- *Italic text*: *text*
- Lists, links, images, code, and more...
`);

  const resetForm = () => {
    setName("");
    setOrder("");
    setEditingBlockUuid(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const data = {
        name,
        order: order ? Number(order) : null,
        training: uuid,
      };

      let savedBlock;
      if (editingBlockUuid) {
        savedBlock = await patchTrainingsBlock(editingBlockUuid, data);
        setBlocks((prev) =>
          prev.map((b) => (b.uuid === editingBlockUuid ? savedBlock : b))
        );
      } else {
        savedBlock = await postTrainingsBlock(data);
        setBlocks((prev) => [...prev, savedBlock]);
      }
      resetForm();
    } catch (err) {
      console.error(t("training.update_blocks.save_block_error", "Не удалось сохранить блок. Попробуйте ещё раз."), err);
      setError(t("training.update_blocks.save_block_error", "Не удалось сохранить блок. Попробуйте ещё раз."));
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (block) => {
    setEditingBlockUuid(block.uuid);
    setName(block.name || "");
    setOrder(block.order ?? "");
  };

  const handleCancel = () => {
    resetForm();
  };

  /**
   * Add new content (video, article, or test) to the specified block.
   */
  const handleAddContent = async (blockUuid, type) => {
    setIsLoading(true);
    setError(null);

    try {
      const currentBlock = blocks.find((b) => b.uuid === blockUuid);
      if (!currentBlock) return;

      let lastOrder = 0;
      if (currentBlock.contents?.length) {
        lastOrder = currentBlock.contents.reduce(
          (max, item) => (item.order > max ? item.order : max),
          0
        );
      }
      const newOrder = lastOrder + 1;

      const createdContent = await postTrainingContentBlock({
        order: newOrder,
        block: blockUuid,
      });

      let fullContent;
      if (type === "video") {
        fullContent = await postTrainingVideoBlock({
          name: t("training.update_blocks.video_name_placeholder", `Video ${newOrder}`),
          authors: t("training.update_blocks.video_authors_placeholder", "Write about the author"),
          contacts: t("training.update_blocks.video_contacts_placeholder", "Provide the author's contacts"),
          content: createdContent.uuid,
        });
        fullContent.content_type = "video";
      } else if (type === "article") {
        fullContent = await postTrainingArticleBlock({
          name: t("training.update_blocks.article_name_placeholder", `Article ${newOrder}`),
          text: markdownText,
          content: createdContent.uuid,
        });
        fullContent.content_type = "article";
      } else if (type === "mcqtest") {
        fullContent = {
          uuid: createdContent.uuid,
          content_type: "mcqtest",
          tests: [],
        };
      }

      const newContentEntry = {
        uuid: createdContent.uuid,
        order: newOrder,
        block: blockUuid,
        content: fullContent,
      };

      setBlocks((prev) =>
        prev.map((b) => {
          if (b.uuid === blockUuid) {
            return {
              ...b,
              contents: [...b.contents, newContentEntry],
            };
          }
          return b;
        })
      );
      setContent(fullContent);
    } catch (err) {
      console.error(t("training.update_blocks.add_content_error", "Не удалось добавить контент. Попробуйте ещё раз."), err);
      setError(t("training.update_blocks.add_content_error", "Не удалось добавить контент. Попробуйте ещё раз."));
    } finally {
      setIsLoading(false);
    }
  };

  // Move content to another block
  const handleMoveContent = async (contentUuid, sourceBlockUuid, targetBlockUuid) => {
    setIsLoading(true);
    setError(null);

    try {
      const updatedContent = await patchTrainingContentBlock(contentUuid, {
        block: targetBlockUuid,
      });

      setBlocks((prev) => {
        // Remove content from source block
        const newState = prev.map((block) => {
          if (block.uuid === sourceBlockUuid) {
            return {
              ...block,
              contents: block.contents.filter((c) => c.uuid !== contentUuid),
            };
          }
          return block;
        });

        // Add content to target block
        return newState.map((block) => {
          if (block.uuid === targetBlockUuid) {
            const maxOrder = block.contents.reduce(
              (acc, c) => (c.order > acc ? c.order : acc),
              0
            );
            const newOrder = maxOrder + 1;

            const movedContent = {
              ...updatedContent,
              order: newOrder,
              block: targetBlockUuid,
            };

            return {
              ...block,
              contents: [...block.contents, movedContent],
            };
          }
          return block;
        });
      });
    } catch (err) {
      console.error(t("training.update_blocks.move_content_error", "Не удалось переместить контент. Попробуйте ещё раз."), err);
      setError(t("training.update_blocks.move_content_error", "Не удалось переместить контент. Попробуйте ещё раз."));
    } finally {
      setIsLoading(false);
    }
  };

  // Delete content
  const handleDeleteContent = async (contentUuid, blockUuid) => {
    const confirmDelete = window.confirm(
      t("training.update_blocks.confirm_delete_content", "Are you sure you want to delete this content?")
    );
    if (!confirmDelete) return;

    setIsLoading(true);
    setError(null);

    try {
      await deleteTrainingContentBlock(contentUuid);

      setBlocks((prev) =>
        prev.map((block) => {
          if (block.uuid === blockUuid) {
            return {
              ...block,
              contents: block.contents.filter((c) => c.uuid !== contentUuid),
            };
          }
          return block;
        })
      );

      if (content && content.uuid && contentUuid === content.uuid) {
        const currentBlock = blocks.find((b) => b.uuid === blockUuid);
        if (currentBlock) {
          const updatedBlockContents = currentBlock.contents.filter(
            (c) => c.uuid !== contentUuid
          );
          const sortedContents = [...updatedBlockContents].sort(
            (a, b) => a.order - b.order
          );

          const deletedOrder = content.order;

          let nextContent = sortedContents.find((c) => c.order > deletedOrder);
          if (nextContent) {
            setContent(nextContent.content);
          } else {
            if (sortedContents.length > 0) {
              setContent(sortedContents[sortedContents.length - 1].content);
            } else {
              setContent(null);
            }
          }
        } else {
          setContent(null);
        }
      }
    } catch (err) {
      console.error(t("training.update_blocks.delete_content_error", "Не удалось удалить контент. Попробуйте ещё раз."), err);
      setError(t("training.update_blocks.delete_content_error", "Не удалось удалить контент. Попробуйте ещё раз."));
    } finally {
      setIsLoading(false);
    }
  };

  // Delete block
  const handleDeleteBlock = async (blockUuid) => {
    const confirmDelete = window.confirm(
      t("training.update_blocks.confirm_delete_block", "Are you sure you want to delete this block?")
    );
    if (!confirmDelete) return;

    setIsLoading(true);
    setError(null);

    try {
      await deleteTrainingsBlock(blockUuid);

      const blockToDelete = blocks.find((b) => b.uuid === blockUuid);
      if (blockToDelete && blockToDelete.contents.some((c) => c.content?.uuid === content?.uuid)) {
        setContent(null);
      }

      setBlocks((prev) => prev.filter((b) => b.uuid !== blockUuid));
    } catch (err) {
      console.error(t("training.update_blocks.delete_block_error", "Не удалось удалить блок. Попробуйте ещё раз."), err);
      setError(t("training.update_blocks.delete_block_error", "Не удалось удалить блок. Попробуйте ещё раз."));
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle block expand/collapse
  const toggleBlockExpand = (blockUuid) => {
    setExpandedBlocks((prev) => ({
      ...prev,
      [blockUuid]: !prev[blockUuid],
    }));
  };

  return (
    <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl p-6">
      {error && <p className="text-red-500 text-sm mb-2">{error}</p>}

      {isLoading && (
        <p className="text-gray-500 text-sm mb-2">
          {t("training.update_blocks.loading", "Loading, please wait...")}
        </p>
      )}

      <h2 className="text-lg font-semibold mb-4">
        {editingBlockUuid
          ? t("training.update_blocks.edit_block", "Edit Block")
          : t("training.update_blocks.add_block", "Add Block")}
      </h2>

      <form onSubmit={handleSubmit} className="space-y-3 mb-6">
        <input
          type="text"
          placeholder={t("training.update_blocks.block_name_placeholder", "Block Name")}
          className="border p-2 rounded-2xl w-full"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          disabled={isLoading}
        />
        <input
          type="number"
          placeholder={t("training.update_blocks.block_order_placeholder", "Order")}
          className="border p-2 rounded-2xl w-full"
          value={order}
          onChange={(e) => setOrder(e.target.value)}
          required
          disabled={isLoading}
        />

        <div className="flex gap-2">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-2xl shadow-md disabled:opacity-50"
            disabled={isLoading}
          >
            {isLoading
              ? t("training.update_blocks.saving", "Saving...")
              : t("training.update_blocks.save_block", "Save Block")}
          </button>
          {editingBlockUuid && (
            <button
              type="button"
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-2xl"
              onClick={handleCancel}
              disabled={isLoading}
            >
              {t("training.cancel", "Cancel")}
            </button>
          )}
        </div>
      </form>

      {blocks?.length ? (
        <div className="space-y-4">
          {blocks.map((block) => {
            const isExpanded = expandedBlocks[block.uuid] || false;
            return (
              <div key={block.uuid} className="border border-gray-200 rounded-2xl p-3">
                {/* Block Header and Buttons */}
                <div className="flex justify-between items-start">
                  <div>
                    <p className="font-medium">
                      {t("training.update_blocks.block_header", "Block {order}: {name}", {
                        order: block.order,
                        name: block.name,
                      })}
                    </p>
                    <p className="text-sm text-gray-600">
                      {t("training.update_blocks.order_label", "Order: {order}", {
                        order: block.order,
                      })}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <button
                      className="bg-yellow-400 px-3 py-1 rounded-2xl text-black disabled:opacity-50"
                      onClick={() => handleEdit(block)}
                      disabled={isLoading}
                    >
                      {t("training.update_blocks.edit", "Edit")}
                    </button>
                    <button
                      className="bg-red-600 text-white px-3 py-1 rounded-2xl disabled:opacity-50"
                      onClick={() => handleDeleteBlock(block.uuid)}
                      disabled={isLoading}
                    >
                      {t("training.update_blocks.delete", "Delete")}
                    </button>
                    <button
                      className="bg-gray-200 text-black px-3 py-1 rounded-2xl disabled:opacity-50 flex items-center gap-1"
                      onClick={() => toggleBlockExpand(block.uuid)}
                      disabled={isLoading}
                    >
                      {isExpanded ? (
                        <>
                          <ChevronUpIcon className="h-4 w-4" />
                          <span>{t("training.update_blocks.collapse", "Collapse")}</span>
                        </>
                      ) : (
                        <>
                          <ChevronDownIcon className="h-4 w-4" />
                          <span>{t("training.update_blocks.expand", "Expand")}</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>

                {/* Expanded Block Content */}
                {isExpanded && (
                  <div className="mt-3">
                    <div className="flex gap-2 mb-3">
                      <button
                        className="bg-green-500 text-white px-3 py-1 rounded-2xl disabled:opacity-50"
                        onClick={() => handleAddContent(block.uuid, "video")}
                        disabled={isLoading}
                      >
                        {t("training.update_blocks.add_video", "Add Video")}
                      </button>
                      <button
                        className="bg-purple-500 text-white px-3 py-1 rounded-2xl disabled:opacity-50"
                        onClick={() => handleAddContent(block.uuid, "article")}
                        disabled={isLoading}
                      >
                        {t("training.update_blocks.add_article", "Add Article")}
                      </button>
                      <button
                        className="bg-blue-500 text-white px-3 py-1 rounded-2xl disabled:opacity-50"
                        onClick={() => handleAddContent(block.uuid, "mcqtest")}
                        disabled={isLoading}
                      >
                        {t("training.update_blocks.add_test", "Add Test")}
                      </button>
                    </div>

                    {block.contents && block.contents.length > 0 ? (
                      <div className="space-y-2">
                        {block.contents.map((c) => (
                          <div
                            key={c.uuid}
                            className={`p-2 rounded-2xl border text-sm flex flex-col gap-1 ${
                              content?.uuid === c.content?.uuid ? "bg-blue-200" : "bg-gray-50"
                            }`}
                            onClick={() => setContent(c.content)}
                          >
                            <div>
                              <strong>
                                {t("training.update_blocks.content_order", "Order:")}
                              </strong>{" "}
                              {c.order} |{" "}
                              <strong>
                                {t("training.update_blocks.content_type", "Type:")}
                              </strong>{" "}
                              {c.content?.content_type || "—"}
                              <br />
                              <strong>
                                {t("training.update_blocks.content_name", "Name:")}
                              </strong>{" "}
                              {c.content?.name}
                            </div>

                            {/* Select for moving content */}
                            <div className="flex gap-2 items-center">
                              <span className="text-xs">
                                {t("training.update_blocks.move_to", "Move to:")}
                              </span>
                              <select
                                className="border rounded-md p-1 text-xs"
                                disabled={isLoading}
                                onChange={(e) =>
                                  handleMoveContent(
                                    c.uuid,
                                    block.uuid,
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">
                                  {t("training.update_blocks.select_block", "Select Block")}
                                </option>
                                {blocks
                                  .filter((b) => b.uuid !== block.uuid)
                                  .map((b) => (
                                    <option key={b.uuid} value={b.uuid}>
                                      {b.name}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            {/* Delete content button */}
                            <div>
                              <button
                                className="bg-red-500 text-white mt-1 px-2 py-1 text-xs rounded-2xl disabled:opacity-50"
                                onClick={() =>
                                  handleDeleteContent(c.uuid, block.uuid)
                                }
                                disabled={isLoading}
                              >
                                {t("training.update_blocks.delete_content", "Delete Content")}
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-sm text-gray-500">
                        {t("training.update_blocks.no_content", "No content available")}
                      </p>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <p>{t("training.update_blocks.no_blocks", "No blocks available")}</p>
      )}
    </div>
  );
};

export default UpdateBlocks;
