import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { patchTrainings } from "../../../../../services/training/catalogService";
import { useTranslation } from "react-i18next";
import Select from "../../../../../components/forms/Select/Select";

const UpdateInfo = ({ training, setTraining, setIsEditing }) => {
  const { uuid } = useParams(); // Берём uuid из URL
  const { t } = useTranslation();
  const [title, setTitle] = useState(training?.name || "");
  const [description, setDescription] = useState(training?.description || "");
  const [duration, setDuration] = useState(training?.duration || "");
  const [forWhom, setForWhom] = useState(training?.for_whom || 0);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = () => {
    setIsSaving(true);
    patchTrainings(uuid, {
      uuid,
      name: title,
      description,
      duration,
      for_whom: forWhom,
    })
      .then((data) => {
        // Обновляем стейт тренинга в родителе
        setTraining(data);
        // Выходим из режима редактирования
        setIsEditing(false);
      })
      .catch((err) => {
        console.error("Ошибка при обновлении тренинга:", err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <div className="w-full max-w-5xl bg-white shadow-md rounded-3xl overflow-hidden mt-0 p-6">
      <h2 className="text-lg font-bold mb-4">{t("training.edit_training")}</h2>
      <Select
        options={[
          { value: 0, label: t("training.for_all") },
          { value: 1, label: t("training.for_students") },
          { value: 2, label: t("training.for_teachers") }
        ]}
        value={forWhom}
        onChange={(selectedOption) => setForWhom(selectedOption?.value || 0)}
        placeholder={t("training.select_audience")}
        className="mb-3 rounded-2xl"
        isClearable
      />
      <div className="flex gap-4 mb-3">
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-3/4 p-3 border rounded-2xl"
          placeholder={t("training.update_info.title_placeholder", "Заголовок")}
        />
        <input
          type="text"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          className="w-1/4 p-3 border rounded-2xl"
          placeholder={t("training.update_info.duration_placeholder", "Время")}
        />
      </div>
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        className="block w-full p-3 mb-3 border rounded-2xl"
        placeholder={t("training.update_info.description_placeholder", "Описание")}
      />
      <div className="flex gap-4">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-2xl shadow-md disabled:opacity-50"
          onClick={handleSave}
          disabled={isSaving}
        >
          {isSaving ? `${t("training.saving")}...` : t("training.save")}
        </button>
        <button
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-2xl shadow-md"
          onClick={handleCancel}
        >
          {t("training.cancel")}
        </button>
      </div>
    </div>
  );
};

export default UpdateInfo;
