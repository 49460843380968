import React, { useEffect, useState, useContext } from "react";
import { postContentProgress, patchContentProgress } from "../../../../../../services/training/progressService";
import { UserContext } from '../../../../../../utils/contexts/UserContext';
import { useTranslation } from "react-i18next";

const parseOptions = (options) => {
  try {
    return typeof options === "string" ? JSON.parse(options) : options;
  } catch (error) {
    console.error("Ошибка парсинга options:", error);
    return ["", "", "", ""];
  }
};

export default function TakeMCQTest({ content, setContent, blocks, setBlocks }) {
  const { t } = useTranslation();
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const { user } = useContext(UserContext);
  
  const questions = content?.tests?.map(test => ({
    question: test.name || "",
    options: parseOptions(test.options),
    answer: test.right || ""
  })) || [];

  // Check if there's already a progress record
  useEffect(() => {
    if (content?.progress_uuid) {
      setSubmitted(true);
    }
  }, [content]);

  const handleSelectAnswer = (index, option) => {
    setSelectedAnswers((prev) => ({ ...prev, [index]: option }));
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    const totalQuestions = questions.length;
    const correctAnswers = questions.reduce((count, q, index) => {
      return count + (selectedAnswers[index] === q.answer ? 1 : 0);
    }, 0);
    const percentage = (correctAnswers / totalQuestions) * 100;
    
    const progressData = {
      percentage,
      user: user.uuid,
      content: content.uuid
    };
    
    try {
      if (content.progress_uuid === null) {
        await postContentProgress(progressData);
      } else {
        await patchContentProgress(content.progress_uuid, progressData);
      }
      
      // Update local content state with new percentage
      setContent(prev => ({
        ...prev,
        percentage,
        progress_percentage: percentage
      }));
      
      // Update the blocks state to reflect the new percentage
      setBlocks(prevBlocks => {
        return prevBlocks.map(block => {
          // Check if this block has our content in its contents array
          const updatedContents = block.contents?.map(contentItem => {
            if (contentItem.content.uuid === content.uuid) {
              // Update this specific content item
              return {
                ...contentItem,
                content: {
                  ...contentItem.content,
                  progress_percentage: percentage
                }
              };
            }
            return contentItem;
          });
          
          if (updatedContents && updatedContents !== block.contents) {
            return {
              ...block,
              contents: updatedContents
            };
          }
          return block;
        });
      });
      
    } catch (error) {
      console.error("Ошибка обновления прогресса:", error);
    }
  };

  const handleTryAgain = () => {
    setSubmitted(false);
    setSelectedAnswers({});
  };

  return (
    <div className="w-full max-w-5xl bg-white shadow-lg rounded-2xl mt-6 p-6 h-[80vh] overflow-y-auto">
      <h2 className="text-2xl font-bold mb-4 text-center">
        {t("training.mcq_test.title", "MCQ Test")}
      </h2>
      
      {/* Show previous result if available */}
      {content?.progress_uuid && !submitted && (
        <div className="mb-6 p-4 bg-blue-100 rounded-2xl">
          <p className="font-medium">
            {t("training.mcq_test.previous_result", "Your previous result:")} <span className="font-bold">{content.progress_percentage?.toFixed(0)}%</span>
          </p>
        </div>
      )}

      {questions.map((q, index) => (
        <div key={index} className="mb-6 p-4 border rounded-2xl shadow-sm bg-gray-50 relative">
          <p className="font-medium text-lg">
            {index + 1}. {q.question}
          </p>
          <div className="mt-2 grid grid-cols-2 gap-2">
            {q.options.map((option, optIndex) => (
              <label
                key={optIndex}
                className={`flex-1 p-3 border rounded-2xl cursor-pointer transition-all duration-300 text-center ${
                  selectedAnswers[index] === option ? "bg-blue-500 text-white" : "bg-gray-100 hover:bg-gray-200"
                }`}
              >
                <input
                  type="radio"
                  name={`question-${index}`}
                  value={option}
                  className="hidden"
                  onChange={() => handleSelectAnswer(index, option)}
                  disabled={submitted}
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      ))}

      {!submitted && (
        <button
          onClick={handleSubmit}
          className="mt-4 px-4 py-2 w-full bg-blue-500 text-white rounded-2xl hover:bg-blue-600"
        >
          {t("training.mcq_test.submit", "Submit")}
        </button>
      )}

      {submitted && (
        <div className="mt-6 p-4 bg-green-100 rounded-2xl">
          <h3 className="font-semibold text-lg">
            {t("training.mcq_test.results_title", "Results:")}
          </h3>
          <p className="mt-2 mb-4 font-bold text-xl">
            {t("training.mcq_test.your_result", "Your result:")} {content.progress_percentage?.toFixed(0)}%
          </p>
          <button
            onClick={handleTryAgain}
            className="mt-6 px-4 py-2 w-full bg-blue-500 text-white rounded-2xl hover:bg-blue-600"
          >
            {t("training.mcq_test.try_again", "Try Again")}
          </button>
        </div>
      )}
    </div>
  );
}