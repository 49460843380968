import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAccountProgress } from "../../../services/training/progressService";
import { useTranslation } from 'react-i18next';

const StudentProgressTable = ({ groupId, groupName }) => {
  const { t } = useTranslation();
  const [accountData, setAccountData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        setLoading(true);
        const params = { group: groupId };
        const result = await getAccountProgress(params);
        setAccountData(result);
        setLoading(false);
      } catch (err) {
        setError(t("training.student_progress.fetch_error"));
        setLoading(false);
        console.error('Error fetching account data:', err);
      }
    };

    fetchAccountData();
  }, [groupId, t]);

  const handleSort = (field) => {
    const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(newDirection);

    const sortedData = [...accountData].sort((a, b) => {
      if (typeof a[field] === 'string') {
        return newDirection === 'asc' 
          ? a[field].localeCompare(b[field]) 
          : b[field].localeCompare(a[field]);
      } else {
        return newDirection === 'asc' 
          ? a[field] - b[field] 
          : b[field] - a[field];
      }
    });

    setAccountData(sortedData);
  };

  const renderSortIcon = (field) => {
    if (sortField !== field) return null;
    return (
      <span className="ml-1">
        {sortDirection === 'asc' ? '▲' : '▼'}
      </span>
    );
  };

  if (loading) {
    return (
      <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-6">
        <div className="p-6 flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-6">
        <div className="p-6 flex justify-center items-center h-64">
          <div className="text-red-500 text-center">
            <p className="text-xl font-semibold">{error}</p>
            <button 
              onClick={() => window.location.reload()} 
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              {t("training.student_progress.try_again")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-6">
      <div className="p-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          {t("training.student_progress.title", { groupName })}
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="w-full h-16 border-b border-gray-200 bg-gray-50">
                <th 
                  className="text-left pl-4 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('first_name')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.student_progress.first_name")} {renderSortIcon('first_name')}
                  </div>
                </th>
                <th 
                  className="text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('last_name')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.student_progress.last_name")} {renderSortIcon('last_name')}
                  </div>
                </th>
                <th 
                  className="text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('courses_started_count')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.student_progress.courses_started")} {renderSortIcon('courses_started_count')}
                  </div>
                </th>
                <th 
                  className="text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('courses_completed_count')}
                >
                  <div className="flex items-center text-sm font-medium text-gray-700">
                    {t("training.student_progress.courses_completed")} {renderSortIcon('courses_completed_count')}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {accountData.length > 0 ? (
                accountData.map((account, index) => (
                  <tr key={account.uuid} className={`h-16 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} border-b`}>
                    <td className="pl-4 text-sm font-medium text-gray-800">
                      {account.first_name}
                    </td>
                    <td className="text-sm text-gray-600 pl-4">
                      {account.last_name}
                    </td>
                    <td className="text-sm text-gray-600 pl-4">
                      <span className="font-medium">{account.courses_started_count}</span>
                    </td>
                    <td className="text-sm text-gray-600 pl-4">
                      <span className="font-medium">{account.courses_completed_count}</span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-8 text-gray-500">
                    {t("training.student_progress.no_data")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        
        <div className="mt-6 flex justify-between items-center">
          <div className="text-sm text-gray-600">
            {t("training.student_progress.showing", { count: accountData.length })}
          </div>
          
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-600">
              {t("training.student_progress.last_updated", { date: new Date().toLocaleDateString() })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProgressTable;
