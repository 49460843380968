import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Blocks = ({ blocks, content, setContent }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("content");
  const [activeBlock, setActiveBlock] = useState(null);

  useEffect(() => {
    if (content) {
      const block = blocks.find((block) =>
        block.contents.some((c) => c.content.uuid === content.uuid)
      );
      if (block) {
        setActiveBlock(block.uuid);
      }
    }
  }, [content, blocks]);

  const tabs = [
    { key: "content", defaultLabel: "Контент" },
    { key: "author", defaultLabel: "Автор" },
    { key: "certificate", defaultLabel: "Сертификат" },
  ];

  return (
    <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl p-6">
      {/* Tabs */}
      <div className="flex bg-gray-100 p-2 rounded-lg mb-4">
        {tabs.map((tab) => (
          <button
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            className={`flex-grow text-center px-6 py-2 rounded-lg text-sm font-medium transition-colors ${
              activeTab === tab.key
                ? "bg-white text-black shadow"
                : "bg-transparent text-gray-500"
            }`}
          >
            {t(`training.block_s.tab.${tab.key}`, tab.defaultLabel)}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      {activeTab === "content" && (
        <div>
          <h2 className="text-lg font-semibold mb-4">
            {t("training.block_s.heading", "Блоки и видео")}
          </h2>
          <div className="flex flex-wrap gap-2 mb-4">
            {blocks.map((block) => (
              <button
                key={block.uuid}
                className={`px-4 py-2 rounded-lg font-medium ${
                  activeBlock === block.uuid ? "bg-blue-500 text-white" : "bg-gray-200"
                }`}
                onClick={() => setActiveBlock(block.uuid)}
              >
                {t("training.block_s.block", "Блок")} {block.order}
              </button>
            ))}
          </div>

          {blocks.map(
            (block) =>
              activeBlock === block.uuid && (
                <div key={block.uuid}>
                  <h2 className="text-lg font-semibold mb-4">{block.name}</h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {block.contents.map((c, index) => {
                      const progressPercentage = c.content.progress_percentage || 0;

                      return (
                        <div
                          key={index}
                          className={`relative rounded-lg shadow hover:bg-gray-50 cursor-pointer overflow-hidden ${
                            content?.uuid === c.content.uuid 
                              ? "border-2 border-purple-500" 
                              : "border border-gray-200"
                          }`}
                          onClick={() => setContent(c.content)}
                        >
                          {/* Progress bar background */}
                          <div 
                            className="absolute left-0 top-0 h-full bg-purple-100" 
                            style={{ width: `${progressPercentage}%` }}
                          ></div>
                          
                          {/* Content (above the progress bar) */}
                          <div className="relative p-4 flex justify-between items-center">
                            <h3 className="font-bold">
                              {c.content.content_type === "mcqtest"
                                ? t("training.block_s.test", "Тест")
                                : c.content.name}
                            </h3>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default Blocks;
