import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const Progress = ({ training, blocks, setBlocks }) => {
  const { t } = useTranslation();

  // Calculate progress metrics from the blocks data
  const progressStats = useMemo(() => {
    let videoCount = 0;
    let videosCompleted = 0;
    let articleCount = 0;
    let articlesCompleted = 0;
    let testCount = 0;
    let testsCompleted = 0;

    // Process all blocks and their contents
    blocks.forEach((block) => {
      if (block.contents && Array.isArray(block.contents)) {
        block.contents.forEach((contentItem) => {
          const content = contentItem.content;
          if (!content) return;
          
          const contentType = content.content_type;
          const progressPercentage = content.progress_percentage;
          const progressUuid = content.progress_uuid;
          const isCompleted = progressUuid !== null && progressPercentage > 75;
          
          if (contentType === "video") {
            videoCount++;
            if (isCompleted) videosCompleted++;
          } else if (contentType === "article") {
            articleCount++;
            if (isCompleted) articlesCompleted++;
          } else if (contentType === "mcqtest") {
            testCount++;
            if (isCompleted) testsCompleted++;
          }
        });
      }
    });

    // Calculate total completion percentage
    const totalItems = videoCount + articleCount + testCount;
    const completedItems = videosCompleted + articlesCompleted + testsCompleted;
    const completionPercentage = totalItems > 0 ? (completedItems / totalItems) * 100 : 0;

    return {
      videoCount,
      videosCompleted,
      articleCount,
      articlesCompleted,
      testCount,
      testsCompleted,
      totalItems,
      completedItems,
      completionPercentage,
    };
  }, [blocks]);

  return (
    <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl p-6">
      <h2 className="text-lg font-semibold mb-4">
        {t("training.course_progress.title", "Progress")}
      </h2>
      <div className="flex flex-wrap gap-4">
        <div className="flex items-center gap-2">
          <span className="bg-purple-500 text-white text-sm px-3 py-1 rounded-full">
            {progressStats.videosCompleted}/{progressStats.videoCount}
          </span>
          <span>{t("training.course_progress.videos", "Videos Watched")}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="bg-green-500 text-white text-sm px-3 py-1 rounded-full">
            {progressStats.articlesCompleted}/{progressStats.articleCount}
          </span>
          <span>{t("training.course_progress.articles", "Articles Read")}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="bg-blue-500 text-white text-sm px-3 py-1 rounded-full">
            {progressStats.testsCompleted}/{progressStats.testCount}
          </span>
          <span>{t("training.course_progress.tests", "Tests Completed")}</span>
        </div>
      </div>
      <div className="mt-4 w-full bg-gray-200 rounded-full h-2">
        <div
          className="bg-purple-500 h-2 rounded-full"
          style={{ width: `${progressStats.completionPercentage}%` }}
        ></div>
      </div>
      <div className="text-sm text-gray-500 mt-1 text-right">
        {t("training.course_progress.completed", "{{percentage}}% completed", {
          percentage: progressStats.completionPercentage.toFixed(1),
        })}
      </div>
    </div>
  );
};

export default Progress;
