import { useState, useEffect } from "react";
import { postMCQTestBlock, patchMCQTestBlock, deleteMCQTestBlock } from "../../../../../../services/training/content";
import { useTranslation } from "react-i18next";

const parseOptions = (options) => {
  try {
    return typeof options === "string" ? JSON.parse(options) : options;
  } catch (error) {
    console.error("Ошибка парсинга options:", error);
    return ["", "", "", ""];
  }
};

export default function EditMCQTest({ blocks, setBlocks, content, setContent, isEditing: initialIsEditing }) {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState([]);
  const [editing, setEditing] = useState(initialIsEditing || false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (content?.tests) {
      setQuestions(
        content.tests.map((test) => ({
          uuid: test.uuid,
          question: test.name || "",
          options: parseOptions(test.options),
          answer: test.right || "",
        }))
      );
    }
  }, [content]);

  // Update local editing state when prop isEditing changes
  useEffect(() => {
    if (initialIsEditing !== undefined) {
      setEditing(initialIsEditing);
    }
  }, [initialIsEditing]);

  const handleAddQuestion = () => {
    if (isLoading) return;
    setQuestions((prev) => [
      ...prev,
      { uuid: null, question: "", options: ["", "", "", ""], answer: "" },
    ]);
  };

  const handleUpdateQuestion = (index, key, value) => {
    if (isLoading) return;
    setQuestions((prev) =>
      prev.map((q, i) => (i === index ? { ...q, [key]: value } : q))
    );
  };

  const handleUpdateAnswer = (index, value) => {
    if (isLoading) return;
    setQuestions((prev) =>
      prev.map((q, i) => (i === index ? { ...q, answer: value } : q))
    );
  };

  const handleDeleteQuestion = async (index) => {
    setIsLoading(true);
    const question = questions[index];
    try {
      if (question.uuid) {
        await deleteMCQTestBlock(question.uuid);
      }
      const updatedQuestions = questions.filter((_, i) => i !== index);
      setQuestions(updatedQuestions);

      const updatedContent = {
        ...content,
        tests: updatedQuestions.map((q) => ({
          uuid: q.uuid,
          is_active: true,
          name: q.question,
          code: null,
          options: JSON.stringify(q.options),
          right: q.answer,
          content: content.uuid,
        })),
        progress_percentage: updatedQuestions.length
          ? Math.floor(
              (updatedQuestions.filter(
                (q) =>
                  q.question &&
                  !q.options.some((opt) => !opt) &&
                  q.answer
              ).length /
                updatedQuestions.length) *
                100
            )
          : 0,
      };

      setContent(updatedContent);

      if (blocks && setBlocks) {
        const updatedBlocks = blocks.map((block) => {
          if (block.contents) {
            const newContents = block.contents.map((contentItem) => {
              if (contentItem.content.uuid === content.uuid) {
                return {
                  ...contentItem,
                  content: updatedContent,
                };
              }
              return contentItem;
            });
            return { ...block, contents: newContents };
          }
          return block;
        });
        setBlocks(updatedBlocks);
      }
    } catch (error) {
      console.error(t("training.edit_mcq_test.delete_question_error", "Ошибка при удалении вопроса:"), error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const updatedTests = await Promise.all(
        questions.map(async (q) => {
          const testData = {
            name: q.question,
            options: JSON.stringify(q.options),
            right: q.answer,
            content: content.uuid,
          };

          if (q.uuid) {
            const updatedTest = await patchMCQTestBlock(q.uuid, testData);
            return { ...updatedTest, question: q.question };
          } else {
            const newTest = await postMCQTestBlock(testData);
            return { ...newTest, question: q.question };
          }
        })
      );

      const updatedContent = {
        ...content,
        tests: updatedTests.map((test) => ({
          uuid: test.uuid,
          is_active: test.is_active || true,
          created: test.created,
          updated: test.updated,
          name: test.question || test.name,
          code: test.code,
          options: test.options,
          right: test.right || test.answer,
          content: test.content,
        })),
      };

      if (blocks && setBlocks) {
        const updatedBlocks = blocks.map((block) => {
          if (block.contents) {
            const newContents = block.contents.map((contentItem) => {
              if (contentItem.content.uuid === content.uuid) {
                return {
                  ...contentItem,
                  content: updatedContent,
                };
              }
              return contentItem;
            });
            return { ...block, contents: newContents };
          }
          return block;
        });
        setBlocks(updatedBlocks);
      }
      setContent(updatedContent);
      setQuestions(
        updatedTests.map((test) => ({
          uuid: test.uuid,
          question: test.question || test.name || "",
          options: parseOptions(test.options),
          answer: test.right || "",
        }))
      );
      setEditing(false);
    } catch (error) {
      console.error(t("training.edit_mcq_test.save_test_error", "Ошибка при сохранении теста:"), error);
    } finally {
      setIsLoading(false);
    }
  };

  const hasEmptyFields = questions.some(
    (q) => !q.question || q.options.some((opt) => !opt) || !q.answer
  );

  const calculateProgress = () => {
    if (questions.length === 0) return 0;
    const totalFields = questions.length;
    const completedFields = questions.filter(
      (q) => q.question && !q.options.some((opt) => !opt) && q.answer
    ).length;
    return Math.floor((completedFields / totalFields) * 100);
  };

  useEffect(() => {
    if (!editing && content) {
      const progress = calculateProgress();
      if (content.progress_percentage !== progress) {
        const updatedContent = {
          ...content,
          progress_percentage: progress,
        };
        setContent(updatedContent);
        if (blocks && setBlocks) {
          const updatedBlocks = blocks.map((block) => {
            if (block.contents) {
              const newContents = block.contents.map((contentItem) => {
                if (contentItem.content.uuid === content.uuid) {
                  return { ...contentItem, content: updatedContent };
                }
                return contentItem;
              });
              return { ...block, contents: newContents };
            }
            return block;
          });
          setBlocks(updatedBlocks);
        }
      }
    }
  }, [editing, content, setContent, blocks, setBlocks]);

  const renderActionButtons = () => (
    <div className="flex justify-between mb-4">
      <button
        onClick={() => (editing ? handleSave() : setEditing(true))}
        className={`px-4 py-2 text-white font-medium rounded-2xl transition-all duration-300 ${
          editing ? "bg-blue-500 hover:bg-blue-600" : "bg-yellow-500 hover:bg-yellow-600"
        } disabled:bg-gray-400`}
        disabled={isLoading || (editing && hasEmptyFields)}
      >
        {editing
          ? t("training.edit_mcq_test.save_test", "Сохранить тест")
          : t("training.edit_mcq_test.edit_test", "Редактировать тест")}
      </button>
      {editing && (
        <button
          onClick={handleAddQuestion}
          className="px-4 py-2 bg-green-500 text-white rounded-2xl hover:bg-green-600 disabled:bg-gray-400"
          disabled={isLoading}
        >
          {t("training.edit_mcq_test.add_question", "Добавить вопрос")}
        </button>
      )}
    </div>
  );

  return (
    <div className={`w-full max-w-5xl bg-white shadow-lg rounded-2xl mt-6 p-6 h-[80vh] overflow-y-auto ${isLoading ? "opacity-50 pointer-events-none" : ""}`}>
      <h2 className="text-2xl font-bold mb-4 text-center">{t("training.mcq_test.title", "MCQ Тест")}</h2>
      {renderActionButtons()}
      {editing && hasEmptyFields && (
        <p className="text-red-500 mb-4">
          {t("training.edit_mcq_test.fill_all_fields", "Заполните все поля вопросов перед сохранением")}
        </p>
      )}
      {questions.length > 0 ? (
        questions.map((q, index) => (
          <div key={index} className="mb-6 p-4 border rounded-2xl shadow-sm bg-gray-50 relative">
            {editing && (
              <button
                onClick={() => handleDeleteQuestion(index)}
                className="absolute top-2 right-2 px-2 py-1 text-white bg-red-500 rounded-2xl hover:bg-red-600 disabled:bg-gray-400"
                disabled={isLoading}
              >
                ✕
              </button>
            )}
            {editing ? (
              <input
                type="text"
                className="border p-2 w-full mb-2 rounded-2xl disabled:bg-gray-200"
                value={q.question}
                onChange={(e) => handleUpdateQuestion(index, "question", e.target.value)}
                placeholder={t("training.edit_mcq_test.question_placeholder", "Введите вопрос")}
                disabled={isLoading}
              />
            ) : (
              <p className="font-medium text-lg">
                {index + 1}. {q.question || t("training.edit_mcq_test.no_title", "Без названия")}
              </p>
            )}
            {editing ? (
              q.options.map((option, optIndex) => (
                <div key={optIndex} className="flex items-center mt-2">
                  <input
                    type="radio"
                    name={`correct-answer-${index}`}
                    checked={q.answer === option}
                    onChange={() => handleUpdateAnswer(index, option)}
                    className="mr-2"
                    disabled={isLoading || !option}
                  />
                  <input
                    type="text"
                    className="border p-2 w-full rounded-2xl disabled:bg-gray-200"
                    value={option}
                    placeholder={t("training.edit_mcq_test.option_placeholder", { order: optIndex + 1 }, "Вариант {order}")}
                    onChange={(e) => {
                      const newOptions = [...q.options];
                      newOptions[optIndex] = e.target.value;
                      handleUpdateQuestion(index, "options", newOptions);
                      if (q.answer === option && !e.target.value) {
                        handleUpdateAnswer(index, "");
                      }
                    }}
                    disabled={isLoading}
                  />
                </div>
              ))
            ) : (
              <div className="mt-2">
                {q.options.map((option, optIndex) => (
                  <div
                    key={optIndex}
                    className={`p-2 my-1 rounded-xl ${q.answer === option ? "bg-green-100 border border-green-300" : "bg-gray-100"}`}
                  >
                    {option}
                    {q.answer === option && <span className="ml-2 text-green-600">✓</span>}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="text-center py-8">
          <p className="text-gray-500 mb-4">
            {t("training.edit_mcq_test.no_questions", "Нет доступных вопросов")}
          </p>
          {editing && (
            <button
              onClick={handleAddQuestion}
              className="px-4 py-2 bg-green-500 text-white rounded-2xl hover:bg-green-600"
            >
              {t("training.edit_mcq_test.create_first_question", "Создать первый вопрос")}
            </button>
          )}
        </div>
      )}
      {questions.length > 0 && (
        <div className="mt-8 pt-4 border-t border-gray-200">
          {renderActionButtons()}
        </div>
      )}
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="text-white text-xl">{t("training.edit_mcq_test.loading", "Загрузка...")}</div>
        </div>
      )}
    </div>
  );
}
