import bilAPI from '../api';

export const postContentProgress = (data) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`training/training_content_progress/`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchContentProgress = (uuid, data) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`training/training_content_progress/${uuid}/`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getOrgProgress = ({forWhom}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`training/training_org_progress/`, {
          params: { page_size: 100, for_whom: forWhom },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};



export const getGroupProgress = ({organization}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`training/training_group_progress/`, {
          params: { page_size: 50, organization },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};



export const getAccountProgress = ({organization, group, is_teacher}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`training/training_account_progress/`, {
          params: { page_size: 50, organization, group, is_teacher },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};




export const getUserProgress = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`training/training_user_progress/`, {
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};



export const getWeeklyProgress = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`training/training_weekly_progress/`, {
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};