import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Training/Sidebar";
import { 
  getTrainings, 
  postTrainingToggleFavoriteByUuid, 
  deleteTrainingByUuid 
} from "../../services/training/catalogService";
import { 
  BookOpenIcon, 
  ClockIcon, 
  HeartIcon as HeartIconOutline, 
  TrashIcon 
} from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Define tabs inside the component so that t() is available
  const tabs = [
    { name: t("training.dashboard.tabs.inProcess"), key: "inProcess" },
    { name: t("training.dashboard.tabs.favorite"), key: "favorite" },
    { name: t("training.dashboard.tabs.myTrainings"), key: "myTrainings" },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);
  const [trainings, setTrainings] = useState({
    inProcess: [],
    favorite: [],
    myTrainings: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllTrainings = async () => {
      try {
        const [inProcess, favorite, myTrainings] = await Promise.all([
          getTrainings({ my_in_process: true }),
          getTrainings({ my_favorite: true }),
          getTrainings({ my_trainings: true }),
        ]);

        setTrainings({
          inProcess,
          favorite,
          myTrainings,
        });
      } catch (error) {
        console.error(t("training.dashboard.fetch_error"), error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllTrainings();
  }, [t]);

  const handleDeleteTraining = (uuid) => {
    if (!window.confirm(t("training.dashboard.delete_confirmation"))) {
      return; // If user cancels deletion
    }

    const categoryKey = tabs.find((tab) => tab.name === activeTab)?.key;
    if (!categoryKey) return;

    // Remove training locally
    setTrainings((prev) => ({
      ...prev,
      [categoryKey]: prev[categoryKey].filter((training) => training.uuid !== uuid),
    }));

    deleteTrainingByUuid(uuid)
      .then(() => console.log(`Training ${uuid} successfully deleted`))
      .catch((error) => {
        console.error(t("training.dashboard.delete_error"), error);
        alert(t("training.dashboard.delete_error"));

        // Restore the courses list if an error occurs
        getTrainings({ [categoryKey]: true })
          .then((data) => setTrainings((prev) => ({ ...prev, [categoryKey]: data })))
          .catch((err) => console.error(t("training.dashboard.restore_error"), err));
      });
  };

  const activeCards =
    trainings[tabs.find((tab) => tab.name === activeTab)?.key] || [];

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6">
      <div className="max-w-7xl mx-auto flex gap-4">
        <Sidebar />

        <div className="flex-1 space-y-5">
          {/* Container card */}
          <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden">
            <div className="p-4">
              {/* Navigation tabs */}
              <div className="flex bg-gray-100 p-2 rounded-lg mb-4">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    onClick={() => setActiveTab(tab.name)}
                    className={`flex-grow text-center px-6 py-2 rounded-lg text-sm font-medium transition-colors ${
                      activeTab === tab.name
                        ? "bg-white text-black shadow"
                        : "bg-transparent text-gray-500"
                    }`}
                  >
                    {tab.name}
                  </button>
                ))}
              </div>

              {/* Card grid */}
              {loading ? (
                <p className="text-gray-500 text-center">
                  {t("training.dashboard.loading")}
                </p>
              ) : activeCards.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {activeCards.map((training) => (
                    <div
                      key={training.uuid}
                      className="rounded-xl overflow-hidden shadow-md bg-white hover:shadow-lg transition-shadow cursor-pointer relative"
                      onClick={() => navigate(`/training/${training.uuid}`)}
                    >
                      {/* Favorite button */}
                      <button 
                        className="absolute top-2 right-2 bg-white p-2 rounded-full shadow-md z-10"
                        onClick={(e) => {
                          e.stopPropagation();

                          const categoryKey = tabs.find((tab) => tab.name === activeTab)?.key;
                          if (!categoryKey) return;

                          // Update local state
                          setTrainings((prev) => ({
                            ...prev,
                            [categoryKey]: prev[categoryKey].map((t) =>
                              t.uuid === training.uuid
                                ? { ...t, is_favorite: !t.is_favorite }
                                : t
                            ),
                          }));

                          // Send toggle request to server
                          postTrainingToggleFavoriteByUuid(training.uuid)
                            .catch((error) => {
                              console.error(t("training.dashboard.toggle_favorite_error"), error);
                              // Roll back state if an error occurs
                              setTrainings((prev) => ({
                                ...prev,
                                [categoryKey]: prev[categoryKey].map((t) =>
                                  t.uuid === training.uuid
                                    ? { ...t, is_favorite: !t.is_favorite }
                                    : t
                                ),
                              }));
                            });
                        }}
                      >
                        {training.is_favorite ? (
                          <HeartIconSolid className="w-6 h-6 text-red-500" />
                        ) : (
                          <HeartIconOutline className="w-6 h-6 text-gray-500" />
                        )}
                      </button>

                      {/* Delete button (shown only if the user is the author) */}
                      {training.is_author && (
                        <button
                          className="absolute bottom-2 right-2 bg-red-500 p-2 rounded-full shadow-md z-10 text-white"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTraining(training.uuid);
                          }}
                        >
                          <TrashIcon className="w-6 h-6" />
                        </button>
                      )}

                      {/* Image */}
                      <img
                        src={training.cover_image}
                        alt={training.name}
                        className="w-full h-40 object-cover"
                      />
                      
                      {/* Content */}
                      <div className="p-4">
                        <h3 className="text-lg font-semibold">
                          {training.name}
                        </h3>
                        <div className="flex items-center space-x-2 mt-2 text-sm text-gray-600">
                          <BookOpenIcon className="w-5 h-5 text-blue-500" />
                          <span>
                            {training.tags?.[0]?.name || t("training.dashboard.default_tag")}
                          </span>
                          <ClockIcon className="w-5 h-5 text-gray-500" />
                          <span>{training.duration}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 text-center">
                  {t("training.dashboard.no_courses")}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
