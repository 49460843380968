import bilAPI from '../api';
import contentAPI from '../contentApi';

export const getTrainingTags = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`training/training_tags/`, {
          params: { page_size: 100 },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};


export const getTrainings = ({my_trainings, my_in_process, my_favorite}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`training/trainings/`, {
          params: { page_size: 100 , my_trainings, my_in_process, my_favorite},
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postTrainings = (body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`training/trainings/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchTrainings = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .patch(`training/trainings/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const getTrainingByUuid = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`training/trainings/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};


export const deleteTrainingByUuid = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`training/trainings/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};


export const postTrainingEnrollByUuid = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`training/trainings/${uuid}/enroll/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};


export const postTrainingToggleFavoriteByUuid = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`training/trainings/${uuid}/toggle_favorite/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};