import React, { useState } from "react";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import { HeartIcon as HeartIconOutline } from "@heroicons/react/24/outline";
import { postTrainingToggleFavoriteByUuid } from "../../../../services/training/catalogService";
import { useTranslation } from "react-i18next";

const Info = ({ training, setTraining }) => {
  const { t } = useTranslation();
  const [isFavorite, setIsFavorite] = useState(training.is_favorite);

  const toggleFavorite = async () => {
    try {
      await postTrainingToggleFavoriteByUuid(training.uuid);
      setIsFavorite((prev) => !prev);
      setTraining((prev) => ({ ...prev, isFavorite: !prev.isFavorite }));
    } catch (error) {
      console.error(t("training.info.favorite_error", "Ошибка при добавлении в избранное:"), error);
    }
  };

  return (
    <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-6">
      <div className="p-6">
        <div className="flex items-center gap-2 mb-4">
          <span className="bg-yellow-300 text-black text-xs px-2 py-1 rounded-full">
            {t("training.info.featured", "★ ?")}
          </span>
          {training.tags.map((tag, index) => (
            <span
              key={index}
              className={`bg-${tag.color}-100 text-${tag.color}-600 text-xs px-2 py-1 rounded-full`}
            >
              {tag.name}
            </span>
          ))}
          <span className="text-xs text-gray-500">{training.duration}</span>
        </div>
        <h1 className="text-2xl font-bold mb-2">{training.name}</h1>
        <p className="text-gray-600 mb-4">{training.description}</p>
        <button
          className="flex items-center text-gray-600 hover:text-gray-800"
          onClick={toggleFavorite}
        >
          {isFavorite ? (
            <HeartIconSolid className="w-6 h-6 text-red-500" />
          ) : (
            <HeartIconOutline className="w-6 h-6 text-gray-500" />
          )}
          <span className="ml-2">
            {isFavorite
              ? t("training.info.favorited", "В избранном")
              : t("training.info.add_to_favorites", "В избранное")}
          </span>
        </button>
      </div>
    </div>
  );
};

export default Info;
