import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserProgress } from "../../../services/training/progressService";
import { UserContext } from "../../../utils/contexts/UserContext";
import {
  HomeIcon,
  MagnifyingGlassIcon,
  HeartIcon,
  UserIcon,
  LockClosedIcon,
  VideoCameraIcon,
  DocumentIcon,
  PencilSquareIcon,
  ChartBarSquareIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [progress, setProgress] = useState({
    videos_completed: 0,
    articles_completed: 0,
    tests_completed: 0,
  });
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const userProgress = await getUserProgress();
        setProgress(userProgress);
      } catch (error) {
        console.error(
          t("training.sidebar.fetch_progress_error", "Failed to fetch user progress:"),
          error
        );
      }
    };
    fetchProgress();
  }, [t]);

  // Check if user has admin or reviewer role
  const hasStatsAccess = () => {
    if (!user || !user.roles) return false;
    return user.roles.some(
      (role) =>
        role.name === "PROJECTADMIN" ||
        role.name === "PROJECTREVIEWER" ||
        role.name === "COURSEADMIN"
    );
  };

  // Define menu items with conditional stats item
  const getMenuItems = () => {
    const items = [
      {
        icon: UserIcon,
        label: t("training.sidebar.profile", "Профиль"),
        path: "/training_course_test_profile",
      },
      {
        icon: MagnifyingGlassIcon,
        label: t("training.sidebar.catalog", "Каталог"),
        path: "/training_course_test_catalog",
      },
      {
        icon: HeartIcon,
        label: t("training.sidebar.my", "Мое"),
        path: "/training_course_test_mycatalog",
      },
    ];

    if (hasStatsAccess()) {
      items.push({
        icon: ChartBarSquareIcon,
        label: t("training.sidebar.stats", "Статистика"),
        path: "/training_course_test_stats",
      });
    }

    return items;
  };

  return (
    <div className="w-60 bg-white rounded-2xl shadow-lg p-4 sticky top-0 h-[360px] overflow-y-auto">
      <nav>
        <ul className="space-y-1">
          {getMenuItems().map((item, index) => {
            const isActive = location.pathname === item.path;
            return (
              <li key={index}>
                <button
                  className={`w-full flex items-center px-3 py-2 rounded-xl transition-colors ${
                    isActive
                      ? "bg-purple-100 text-purple-600"
                      : "text-gray-700 hover:bg-gray-100"
                  }`}
                  onClick={() => navigate(item.path)}
                >
                  <item.icon
                    className={`w-5 h-5 mr-3 ${
                      isActive ? "text-purple-600" : "text-gray-400"
                    }`}
                  />
                  <span className="text-sm font-medium">{item.label}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="absolute bottom-6 mt-5 space-y-3 bg-gray-50 p-4 rounded-xl">
        <div className="flex items-center space-x-2">
          <div className="w-7 h-7 bg-blue-100 rounded-full flex items-center justify-center">
            <PencilSquareIcon className="w-4 h-4 text-blue-600" />
          </div>
          <span className="text-xs text-gray-600">
            {t("training.sidebar.tests_completed", {
              count: progress.tests_completed,
              defaultValue: "{{count}} Тестов пройдено",
            })}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="w-7 h-7 bg-purple-100 rounded-full flex items-center justify-center">
            <VideoCameraIcon className="w-4 h-4 text-purple-600" />
          </div>
          <span className="text-xs text-gray-600">
            {t("training.sidebar.videos_completed", {
              count: progress.videos_completed,
              defaultValue: "{{count}} Видео просмотрено",
            })}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="w-7 h-7 bg-green-100 rounded-full flex items-center justify-center">
            <DocumentIcon className="w-4 h-4 text-green-600" />
          </div>
          <span className="text-xs text-gray-600">
            {t("training.sidebar.articles_completed", {
              count: progress.articles_completed,
              defaultValue: "{{count}} Статей прочитано",
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
