import React, { useState, useEffect } from "react";
import { MagnifyingGlassIcon, FunnelIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { getTrainingTags } from '../../../../services/training/catalogService';
import { useTranslation } from "react-i18next";

const SearchBar = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([t("training.search_bar.all_category", "All")]);
  const [activeCategory, setActiveCategory] = useState(t("training.search_bar.all_category", "All"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTrainingTags()
      .then((tags) => {
        const tagNames = tags.map(tag => tag.name);
        setCategories([t("training.search_bar.all_category", "All"), ...tagNames]);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      })
      .finally(() => setLoading(false));
  }, [t]);

  return (
    <div className="bg-white p-4 rounded-2xl shadow-md flex flex-col space-y-3 w-full">
      {/* Search Field */}
      <div className="flex items-center bg-gray-100 px-4 py-2 rounded-lg">
        <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" />
        <input
          type="text"
          placeholder={t("training.search_bar.placeholder", "Search")}
          className="flex-1 bg-transparent outline-none px-3 text-gray-700"
        />
        <button className="p-2 text-gray-500">
          <FunnelIcon className="w-5 h-5" />
        </button>
      </div>

      {/* Filters */}
      <div className="flex items-center space-x-2 overflow-x-auto scrollbar-hide">
        {loading ? (
          <p className="text-gray-500">{t("training.search_bar.loading", "Loading...")}</p>
        ) : (
          categories.map((category, index) => (
            <button
              key={index}
              onClick={() => setActiveCategory(category)}
              className={`px-4 py-2 rounded-full font-medium text-sm transition ${
                activeCategory === category
                  ? "bg-purple-100 text-purple-600"
                  : "bg-gray-100 text-gray-700"
              }`}
            >
              {category}
            </button>
          ))
        )}
        <button className="p-2">
          <ChevronRightIcon className="w-5 h-5 text-gray-400" />
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
