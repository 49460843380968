import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../utils/contexts/UserContext";
import { 
  HeartIcon as HeartIconSolid, 
  TrashIcon 
} from "@heroicons/react/24/solid";
import { 
  HeartIcon as HeartIconOutline, 
  ClockIcon, 
  BookOpenIcon 
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { 
  getTrainings, 
  postTrainings, 
  postTrainingToggleFavoriteByUuid, 
  deleteTrainingByUuid 
} from "../../../../services/training/catalogService";
import Upload from "../../../../components/upload/Upload";
import { useTranslation } from "react-i18next";

const CardGrid = () => {
  const navigate = useNavigate();
  const [trainings, setTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newTrainingCover, setNewTrainingCover] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  useEffect(() => {
    getTrainings({})
      .then((data) => {
        setTrainings(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading data: ", error);
        setLoading(false);
      });
  }, []);

  const handleCreateTraining = () => {
    if (!newTrainingCover) {
      alert(t("training.card_grid.create_training_warning"));
      return;
    }

    setIsUploading(true);

    const newTraining = {
      is_active: true,
      name: "Измените имя курса", // You might translate this later if needed
      description: "Здесь будет ...", // Same for description
      duration: "10 часов", // And duration
      cover_image: newTrainingCover,
    };

    postTrainings(newTraining)
      .then((data) => {
        setTrainings((prev) => [data, ...prev]);
        navigate(`/training/${data.uuid}`);
      })
      .catch((error) => {
        console.error("Error creating training:", error);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleDeleteTraining = (uuid) => {
    if (!window.confirm(t("training.card_grid.delete_confirmation"))) {
      return; // Cancel deletion if the user selects "No"
    }

    // Optimistic UI update: remove training from the list immediately
    setTrainings((prev) => prev.filter((training) => training.uuid !== uuid));

    deleteTrainingByUuid(uuid)
      .then(() => {
        console.log(`Training ${uuid} deleted successfully`);
      })
      .catch((error) => {
        console.error("Error deleting training:", error);
        alert(t("training.card_grid.delete_error"));

        // Restore the training list in case of error
        getTrainings({})
          .then((data) => setTrainings(data))
          .catch((err) => console.error("Error restoring list:", err));
      });
  };

  if (loading) {
    return <div className="text-center p-6">{t("training.card_grid.loading", "Loading...")}</div>;
  }

  return (
    <div className="bg-white p-6 rounded-xl shadow-md w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* Card for creating new training */}
        {user?.roles.some((role) => role.name === 'COURSEADMIN') && (
          <div className="rounded-xl overflow-hidden shadow-md bg-gray-100 hover:shadow-lg transition-shadow p-4 flex flex-col items-center justify-center">
            <Upload setFile={setNewTrainingCover} accept="image/*" />
            <button
              className={`mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg w-full ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={handleCreateTraining}
              disabled={isUploading}
            >
              {isUploading 
                ? `${t("training.card_grid.creating")}...` 
                : t("training.card_grid.create_training")}
            </button>
          </div>
        )}

        {trainings.map((training, index) => (
          <div 
            key={training.uuid || index} 
            className="rounded-xl overflow-hidden shadow-md bg-white hover:shadow-lg transition-shadow cursor-pointer relative"
            onClick={() => navigate(`/training/${training.uuid}`)}
          >
            {/* Image */}
            <img src={training.cover_image} alt={training.name} className="w-full h-40 object-cover" />

            {/* Favorite button */}
            <button 
              className="absolute top-2 right-2 bg-white p-2 rounded-full shadow-md z-10"
              onClick={(e) => {
                e.stopPropagation();
                setTrainings((prev) =>
                  prev.map((t) => 
                    t.uuid === training.uuid ? { ...t, is_favorite: !t.is_favorite } : t
                  )
                );

                postTrainingToggleFavoriteByUuid(training.uuid)
                  .catch((error) => {
                    console.error("Error toggling favorite:", error);
                    setTrainings((prev) =>
                      prev.map((t) => 
                        t.uuid === training.uuid ? { ...t, is_favorite: !t.is_favorite } : t
                      )
                    );
                  });
              }}
            >
              {training.is_favorite ? (
                <HeartIconSolid className="w-6 h-6 text-red-500" />
              ) : (
                <HeartIconOutline className="w-6 h-6 text-gray-500" />
              )}
            </button>

            {/* Delete button (only if author) */}
            {training.is_author && (
              <button
                className="absolute bottom-2 right-2 bg-red-500 p-2 rounded-full shadow-md z-10 text-white"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteTraining(training.uuid);
                }}
              >
                <TrashIcon className="w-6 h-6" />
              </button>
            )}

            {/* Content */}
            <div className="p-4">
              <h3 className="text-lg font-semibold">{training.name}</h3>
              <div className="flex items-center space-x-2 mt-2 text-sm text-gray-600">
                <BookOpenIcon className="w-5 h-5 text-blue-500" />
                <span>{training.tags?.[0]?.name || t("training.card_grid.default_tag", "Training")}</span>
                <ClockIcon className="w-5 h-5 text-gray-500" />
                <span>{training.duration}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardGrid;
