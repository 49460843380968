import bilAPI from '../api';
import contentAPI from '../contentApi';


export const getTrainingsBlock = ({ training }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`training/training_blocks/`, {
          params: { page_size: 100, training },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postTrainingsBlock = (data) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`training/training_blocks/`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchTrainingsBlock = (uuid, data) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`training/training_blocks/${uuid}/`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteTrainingsBlock = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`training/training_blocks/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};


export const postTrainingContentBlock = (data) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`training/training_content/`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};


export const patchTrainingContentBlock = (uuid, data) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`training/training_content/${uuid}/`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};


export const deleteTrainingContentBlock = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`training/training_content/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postTrainingArticleBlock = (data) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`training/training_article/`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchTrainingArticleBlock = (uuid, data) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`training/training_article/${uuid}/`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postTrainingVideoBlock = (data) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`training/training_video/`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchTrainingVideoBlock = (uuid, data) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`training/training_video/${uuid}/`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};


export const postMCQTestBlock = (data) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`training/training_mcq/`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchMCQTestBlock = (uuid, data) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`training/training_mcq/${uuid}/`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};


export const deleteMCQTestBlock = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`training/training_mcq/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};