import React, { useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../utils/contexts/UserContext';
import { postContentProgress, patchContentProgress } from "../../../../services/training/progressService";

const VideoNavigation = ({ setBlocks, blocks, content, setContent, isStart }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  

  if (!content || !content.uuid) return null;

  const handleContentChange = async (direction) => {
    if (!content || !blocks.length) return;
    
    // Handle article completion confirmation if needed
    if (direction === "next" && 
        content.content_type === "article" && 
        isStart && 
        !content.progress_uuid && 
        !showConfirmation) {
      setShowConfirmation(true);
      return;
    }
    
    let currentBlockIndex = blocks.findIndex((block) =>
      block.contents.some((c) => c.content?.uuid === content?.uuid)
    );
    if (currentBlockIndex === -1) return;
    let currentBlock = blocks[currentBlockIndex];
    let contentIndex = currentBlock.contents.findIndex(
      (c) => c.content?.uuid === content?.uuid
    );
    let newContent = null;
    if (direction === "prev") {
      if (contentIndex > 0) {
        newContent = currentBlock.contents[contentIndex - 1].content;
      } else if (currentBlockIndex > 0) {
        newContent = blocks[currentBlockIndex - 1].contents.slice(-1)[0].content;
      }
    } else if (direction === "next") {
      if (contentIndex < currentBlock.contents.length - 1) {
        newContent = currentBlock.contents[contentIndex + 1].content;
      } else if (currentBlockIndex < blocks.length - 1) {
        newContent = blocks[currentBlockIndex + 1].contents[0].content;
      }
    }
    if (newContent) {
      setContent(newContent);
    }
  };

  const handleConfirmCompletion = async (confirmed) => {
    if (confirmed) {
      setIsSubmitting(true);
      // Close the confirmation dialog immediately
      setShowConfirmation(false);
      
      // Update the blocks array with progress information
      const updatedBlocks = [...blocks];
      const currentBlockIndex = updatedBlocks.findIndex((block) =>
        block.contents.some((c) => c.content?.uuid === content?.uuid)
      );
      
      if (currentBlockIndex !== -1) {
        const currentBlock = updatedBlocks[currentBlockIndex];
        const contentIndex = currentBlock.contents.findIndex(
          (c) => c.content?.uuid === content?.uuid
        );
        
        if (contentIndex !== -1) {
          // Create a temporary progress UUID (will be replaced with the real one from the API)
          const tempProgressUuid = `temp-${Date.now()}`;
          
          // Update the content with progress information
          const updatedContent = {
            ...currentBlock.contents[contentIndex].content,
            progress_percentage: 100,
            progress_uuid: tempProgressUuid
          };
          
          // Update the blocks state
          updatedBlocks[currentBlockIndex].contents[contentIndex].content = updatedContent;
          setBlocks(updatedBlocks);
        }
      }
      
      // Navigate to the next page immediately
      let newContent = null;
      if (currentBlockIndex !== -1) {
        const currentBlock = updatedBlocks[currentBlockIndex];
        const contentIndex = currentBlock.contents.findIndex(
          (c) => c.content?.uuid === content?.uuid
        );
        
        if (contentIndex < currentBlock.contents.length - 1) {
          newContent = currentBlock.contents[contentIndex + 1].content;
        } else if (currentBlockIndex < updatedBlocks.length - 1) {
          newContent = updatedBlocks[currentBlockIndex + 1].contents[0].content;
        }
        
        if (newContent) {
          setContent(newContent);
        }
      }
      
      // Submit progress in the background
      try {
        const percentage = 100;
        const progressData = {
          percentage,
          user: user.uuid,
          content: content.content
        };
        
        // Don't await, let it run in background
        postContentProgress(progressData)
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
              // Update the blocks with the real progress UUID from the response
              if (response.data && response.data.uuid) {
                const finalUpdatedBlocks = [...blocks];
                const blockIndex = finalUpdatedBlocks.findIndex((block) =>
                  block.contents.some((c) => c.content?.uuid === content?.uuid)
                );
                
                if (blockIndex !== -1) {
                  const block = finalUpdatedBlocks[blockIndex];
                  const cIndex = block.contents.findIndex(
                    (c) => c.content?.uuid === content?.uuid
                  );
                  
                  if (cIndex !== -1) {
                    finalUpdatedBlocks[blockIndex].contents[cIndex].content = {
                      ...finalUpdatedBlocks[blockIndex].contents[cIndex].content,
                      progress_percentage: 100,
                      progress_uuid: response.data.uuid
                    };
                    
                    setBlocks(finalUpdatedBlocks);
                  }
                }
              }
            } else {
              console.error("Error submitting progress:", response);
            }
          })
          .catch(error => {
            console.error("Error submitting progress:", error);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } catch (error) {
        console.error("Error submitting progress:", error);
        setIsSubmitting(false);
      }
    } else {
      // User hasn't completed reading, just close the confirmation
      setShowConfirmation(false);
    }
  };

  const isFirstContent = blocks[0]?.contents[0]?.content?.uuid === content?.uuid;
  const isLastContent = blocks[blocks.length - 1]?.contents.slice(-1)[0]?.content?.uuid === content?.uuid;
  
  const getContentName = (content) => {
    if (content.content_type === "mcqtest") {
      return "Тест";
    }
    if (content.content_type === "article") {
      return content.name || "Статья";
    }
    return content.name || "Видео";
  };
  
  return (
    <>
      <div className="flex justify-between items-center px-4 py-4 border-b">
        <button
          className={`text-gray-600 flex items-center gap-2 transition-colors duration-200 ${isFirstContent ? "opacity-50 cursor-not-allowed" : "hover:text-gray-800"}`}
          onClick={() => !isFirstContent && handleContentChange("prev")}
          disabled={isFirstContent}
        >
          <span>&larr; {t("training.prev_page")}</span>
        </button>
        <div className="flex flex-col items-center">
          <span className="text-sm text-gray-500 font-medium">
            {blocks.find((block) => block.contents.some((c) => c.content?.uuid === content?.uuid))?.name || "Блок"}
          </span>
          <span className="text-base font-semibold text-gray-700">
            {getContentName(content)}
          </span>
        </div>
        <button
          className={`text-gray-600 flex items-center gap-2 transition-colors duration-200 ${isLastContent ? "opacity-50 cursor-not-allowed" : "hover:text-gray-800"}`}
          onClick={() => !isLastContent && handleContentChange("next")}
          disabled={isLastContent || isSubmitting}
        >
          <span>{t("training.next_page")} &rarr;</span>
          {isSubmitting && (
            <svg className="animate-spin h-4 w-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          )}
        </button>
      </div>
      
      {/* Confirmation Dialog */}
      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-lg font-medium text-gray-900 mb-3">
              {t("training.article_completion")}
            </h3>
            <p className="text-gray-600 mb-4">
              {t("training.article_completion_question")}
            </p>
            <div className="flex justify-end gap-3">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors"
                onClick={() => handleConfirmCompletion(false)}
                disabled={isSubmitting}
              >
                {t("common.no")}
              </button>
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors flex items-center gap-2"
                onClick={() => handleConfirmCompletion(true)}
                disabled={isSubmitting}
              >
                {t("common.yes")}
                {isSubmitting && (
                  <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoNavigation;